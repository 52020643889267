import React, { useState } from "react";

const ConfirmButton = ({ text, onClick }) => {
    const [isConfirming, setIsConfirming] = useState(false);
    return (
        <div>
            {!isConfirming && (
                <button
                    className="button -secondary -small"
                    onClick={() => {
                        setIsConfirming(true);
                    }}
                >
                    {text || "Delete"}
                </button>
            )}
            {isConfirming && (
                <div className="confirmation">
                    <p>Are you sure?</p>
                    <div className="confirmation_options">
                        <button
                            className="button -primary -small"
                            onClick={() => {
                                onClick();
                            }}
                        >
                            Yes
                        </button>
                        <button
                            className="button -small"
                            onClick={() => {
                                setIsConfirming(false);
                            }}
                        >
                            No
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ConfirmButton;
