import React from "react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

import DefaultText from "./compontents/default-text";
import EngineLoader from "$components/loaders/engine-loader";
import LargeNumber from "$components/info-list/content/large-number";
import LargeNumberRange from "$components/table/cells/large-number-range";
import NoData from "$components/charts/no-content";
import ProbabilityBubble from "$components/probability";
import ScoreBubble from "$components/score-bubble";

import { abbrNum } from "$utils/number-helper";
import { checkIsHealthy } from "$utils/health-helper";
import { getKeyMetaData } from "src/pages/dashboard/lib/helpers";
import { encodeStringTemplateReplace } from "$utils/template-replace";
import { scrollToPos } from "$utils/scroll-to";

import "./top5.scss";

const Top5 = ({ data, linkTemplate, LabelComponent = DefaultText, onClick, metrics, filters, title }) => {
    const appInsights = useAppInsightsContext();
    const navigate = useNavigate();
    return (
        <div className="top5">
            {title && (
                <header>
                    <h4 dangerouslySetInnerHTML={{ __html: title }}></h4>
                    {filters && (
                        <h4 className="txt -no-margin filter-display txt -gray">
                            {Object.keys(filters).map((k, i) => {
                                const keyMeta = getKeyMetaData(k);

                                return (
                                    <span key={`filter_${i}`}>
                                        <h5 className="txt -no-margin">{keyMeta?.text}:</h5>
                                        {keyMeta.renderData(filters[k])}
                                    </span>
                                );
                            })}
                        </h4>
                    )}
                </header>
            )}
            <ul className="top5-items">
                {!data ? (
                    <EngineLoader />
                ) : data.length > 0 ? (
                    data.map((item, i) => {
                        //const value = type === "currency" ? `$${abbrNum(item.value)}` : abbrNum(item.value);
                        return (
                            <li key={`top5-item_${i}`} data-number={i + 1}>
                                <div className="title-column">
                                    <span
                                        className={classNames({ "txt -cursor": !!linkTemplate || !!onClick })}
                                        onClick={
                                            !!onClick
                                                ? () => {
                                                      onClick(item);
                                                  }
                                                : !!linkTemplate
                                                ? () => {
                                                      appInsights.trackEvent(
                                                          { name: "Top5 Navigation" },
                                                          {
                                                              label: item?.name || "",
                                                              page: document.title.replace(" - Orgaimi", ""),
                                                          }
                                                      );

                                                      scrollToPos(0);
                                                      navigate(encodeStringTemplateReplace(linkTemplate, item));
                                                  }
                                                : null
                                        }
                                    >
                                        <LabelComponent value={item.name} />
                                    </span>
                                    {metrics &&
                                        metrics.map((m, mI) => {
                                            switch (m) {
                                                case "clientGroupName":
                                                    return (
                                                        <h5
                                                            key={`clientGroupName_${i}_${mI}`}
                                                            className="txt -cursor"
                                                            onClick={() => {
                                                                scrollToPos(0);
                                                                navigate(encodeStringTemplateReplace("/client/#{clientGroupId}", item));
                                                            }}
                                                        >
                                                            {item[m]}
                                                        </h5>
                                                    );
                                                default:
                                                    return null;
                                            }
                                        })}
                                    {item.isCrossSell && <span className="txt -pill -importance">Cross-Sell</span>}
                                </div>
                                {metrics &&
                                    metrics.map((m, i) => {
                                        switch (m) {
                                            case "name":
                                                return null;
                                            case "averageRevenue":
                                                return <LargeNumberRange key={`metric_${i}`} value={item[m]} decimalPrecision={0} />;
                                            case "growthPotential":
                                            case "revenueAtRisk":
                                                return <LargeNumber key={`metric_${i}`} value={`$${abbrNum(item[m])}`} />;
                                            case "revenueHistoryTotal":
                                                return <LargeNumber key={`metric_${i}`} value={`$${abbrNum(item[m])}`} isHealthy={true} />;
                                            case "healthScore":
                                                return <ScoreBubble key={`metric_${i}`} healthy={checkIsHealthy(item[m])} arrow={false} graph={false} value={item[m]} modifierClassNames={["-small"]} />;
                                            case "probability":
                                                return <ProbabilityBubble key={`metric_${i}`} value={item[m]} />;
                                            case "sviScore":
                                                return <ProbabilityBubble key={`metric_${i}`} value={item[m] * 10} cssClasses={["-importance"]} />;
                                            default:
                                                return null;
                                        }
                                    })}
                            </li>
                        );
                    })
                ) : (
                    <NoData />
                )}
            </ul>
        </div>
    );
};

export default Top5;
