import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import logger from "$services/logger";

import DefaultCell from "./cells/default";
import NoContent from "$components/no-content";
import Pagination from "./components/pagination";
import ShadowTable from "$components/loaders/shadow-table";
import SortButton from "./components/sort";
import TableSettingsModal from "./components/table-settings";

import { AppContextStore } from "$stores/app-context";
import { DefaultFilterForColumn } from "./components/filters/filter";
import { initiateModal } from "$stores/modal";

import "./table.scss";

const Table = ({
    allowFilter = false,
    columns,
    data,
    filters = {},
    hiddenColumns = [],
    isLoading,
    onRowClick,
    pageCount = 1,
    pageSize,
    pageIndex = 0,
    selectedData = [],
    setFilters,
    setHiddenColumns,
    setPageIndex,
    setPerPage,
    showPagination = true,
    setSort,
    sort,
}) => {
    const [rowHover, setRowHover] = useState(null);
    const [useColumns, setUseColumns] = useState(columns.filter((c) => !hiddenColumns.includes(c.accessorKey)));
    const { configurableColumns } = AppContextStore.useState((f) => ({
        configurableColumns: f.settings?.features?.configurableColumns || false,
    }));
    useEffect(() => {
        setUseColumns(columns.filter((c) => !hiddenColumns.includes(c.accessorKey)));
    }, [columns, hiddenColumns]);

    const updateFilters = (id, value) => {
        if (setFilters) {
            if (!value || _.isEmpty(value)) {
                // Remove
                const newFilters = { ...filters };
                delete newFilters[id];
                setFilters(newFilters);
            } else {
                // Add
                setFilters({ ...filters, [id]: value });
            }
        } else {
            logger.error("setFilters is not defined");
        }
    };

    const updateSort = useMemo(
        () => (id, value, persist) => {
            const newSort = persist ? { ...sort } : {};

            if (!value) {
                // Remove
                delete newSort[id];
                setSort(newSort);
            } else {
                // Add
                setSort({ ...newSort, [id]: value });
            }
        },
        [sort]
    );

    const handleTableSettingsClick = () => {
        initiateModal(TableSettingsModal, { columns: columns, hiddenColumns: hiddenColumns, setHiddenColumns }, { analyticsLabel: "Modal - Table Settings" });
    };

    const HeaderGroups = useColumns.map((header, index) => {
        const FilterComp = header.Filter ? header.Filter : DefaultFilterForColumn;
        return (
            <div
                key={`header_col${index}_${header.accessorKey || header.id}`}
                id={`header_${header.accessorKey || header.id}`}
                className={classNames("cell -header -sticky-top", { [header.className]: !!header.className, "-sortable": header.enableSorting !== false, "-end": useColumns.length === index + 1 })}
            >
                <div
                    className="header-label"
                    {...{
                        className: header.enableSorting !== false ? "cursor-pointer select-none" : "",
                        onClick: () => {},
                    }}
                >
                    {<header.Header data={data} selectedData={selectedData} />}
                    {header.enableSorting !== false && <SortButton id={header.accessorKey} sortDescFirst={header.sortDescFirst} updateSort={updateSort} value={sort ? sort[header.accessorKey] : null} />}
                </div>
                <div className="column-filter">
                    {allowFilter && header.enableColumnFilter !== false ? (
                        <FilterComp id={header.accessorKey || header.id} setFilterValue={updateFilters} value={filters[header.accessorKey] || ""} filters={header.filters} {...(header.filterOptions || {})} />
                    ) : null}
                </div>
            </div>
        );
    });

    const RowsHTML = data.map((row, rowIndex) => {
        return useColumns.map((cell, index) => {
            const CellComp = cell.Cell ? cell.Cell : DefaultCell;

            if (cell.enableRowSpan && rowIndex > 0) return null;
            const spannedStyle = cell.enableRowSpan && rowIndex === 0 ? { gridColumn: index + 1, gridRow: `2 / span ${data.length}` } : {};
            return (
                <div
                    key={`row${rowIndex}_col${index}_cell_${cell.accessorKey || cell.id}`}
                    className={classNames("cell", {
                        [cell.className]: cell.className,
                        "-clickable": !!onRowClick,
                        "-hover": !!onRowClick && row.key === rowHover,
                        "-end": useColumns.length === index + 1,
                        "-fade": row.userFlagged,
                    })}
                    onClick={
                        onRowClick
                            ? () => {
                                  onRowClick(row);
                              }
                            : null
                    }
                    onMouseEnter={() => {
                        setRowHover(row.key);
                    }}
                    onMouseLeave={() => {
                        setRowHover(null);
                    }}
                    style={{ ...spannedStyle }}
                >
                    <CellComp data={row} value={row[cell.accessorKey || cell.id]} rowIndex={rowIndex} isSelected={selectedData.some((r) => r.key === row.key)} />
                </div>
            );
        });
    });

    return (
        <>
            <div className="table" style={{ gridTemplateColumns: `repeat(${useColumns.length}, auto)` }}>
                {!!setHiddenColumns && configurableColumns && (
                    <div className="table-settings" onClick={handleTableSettingsClick}>
                        <FontAwesomeIcon icon={faGear} />
                    </div>
                )}
                {HeaderGroups}
                {isLoading ? (
                    <div style={{ gridColumn: `1 / span ${useColumns.length}` }}>
                        <ShadowTable />
                    </div>
                ) : (
                    <>{RowsHTML}</>
                )}
            </div>

            {!isLoading && data.length <= 0 && (
                <div className="table-no-data">
                    <NoContent />
                </div>
            )}
            {showPagination && data && data.length > 0 && <Pagination pageIndex={pageIndex} pageSize={pageSize} pageCount={pageCount} setPageIndex={setPageIndex} setPerPage={setPerPage} />}
        </>
    );
};

export default Table;
