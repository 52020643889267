import PaginatedStore from "./base/PaginatedStore";
import { getTerm } from "./dictionary";

export const DEFAULT_SORT = { name: "asc" };
const CLIENT_STORAGE_KEY = "/api/projects/potential";

const { store, fetchList, reset, setFilters, setFlagged, setHiddenColumns, setLoading, setPageIndex, setPagingInfo, setPerPage, setSelectedData, setSort } = PaginatedStore({
    key: "clientPotentialProjects",
    label: getTerm("potentialProjects"),
    apiListUrl: CLIENT_STORAGE_KEY,
    defaultSort: DEFAULT_SORT,
    persistPagingInfo: true,
    storageKey: CLIENT_STORAGE_KEY,
});

export const ClientPotentialProjectsStore = store;

export const fetchClientPotentialProjects = fetchList;

export { reset, setFilters, setFlagged, setHiddenColumns, setLoading, setPageIndex, setPagingInfo, setPerPage, setSelectedData, setSort };
