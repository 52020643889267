import React, { useEffect } from "react";
import MDEditor from "@uiw/react-md-editor";
import { sendSystemNotificationToAll } from "$stores/notifications";
import { NotificationTemplateStore, fetchNotificationTemplates, saveNotificationTemplates } from "./store";

import "./notify.scss";
import NotificationTemplateList from "./components/NotificationTemplateList";

const NotifyAdmin = () => {
    const [id, setId] = React.useState("");
    const [subject, setSubject] = React.useState("");
    const [message, setMessage] = React.useState("");
    const [appMessage, setAppMessage] = React.useState("");
    const [notificationMessageType, setNotificationMessageType] = React.useState("");

    const [key, setKey] = React.useState("");

    const [error, setError] = React.useState("");
    const notificationTemplates = NotificationTemplateStore.useState((s) => s.notificationTemplates);

    useEffect(() => {
        fetchNotificationTemplates();
    }, []);

    const populateClear = () => {
        setSubject("");
        setAppMessage("");
        setMessage("");
        setNotificationMessageType("");
        setError("");
        setKey("");
        setId("");
    };

    const populateTemplate = (template) => {
        setSubject(template.subject);
        setAppMessage(template.appMessage);
        setMessage(template.message);
        setKey(template.templateKey);
        setId(template.id);
    };

    const validateCurrentTemplate = () => {
        if (!key || !message || !subject || !appMessage) {
            setError("Template Key, Subject, and Message are required");
            return false;
        }
        return true;
    };

    const sendNotificationAll = () => {
        if (validateCurrentTemplate) {
            sendSystemNotificationToAll(subject, message, appMessage, notificationMessageType);
            setError("Sent!");
        }
    };

    const saveNotification = () => {
        const newData = {
            templateKey: key,
            subject: subject,
            message: message,
            appMessage: appMessage,
        };
        if (id) {
            newData.id = id;
        }
        saveNotificationTemplates(newData);
    };

    return (
        <div className="notify-admin">
            <div className="messages">
                <h2>Notify</h2>
                {error && <div className="error">{error}</div>}
                <div className="button-group">
                    <button onClick={populateClear} className="button -secondary -warning -small">
                        Clear
                    </button>
                    <button onClick={saveNotification} className="button -primary -small">
                        Save
                    </button>
                </div>
                <div className="form-field">
                    <input
                        type="text"
                        placeholder="Key"
                        value={key}
                        onChange={(e) => {
                            setError("");
                            setKey(e.currentTarget.value);
                        }}
                    />
                </div>
                <div className="form-field">
                    <label>Subject</label>
                    <input
                        type="text"
                        value={subject}
                        onChange={(e) => {
                            setError("");
                            setSubject(e.currentTarget.value);
                        }}
                    />
                </div>
                <div data-color-mode="dark">
                    <label>App Message</label>
                    <MDEditor
                        height={200}
                        value={appMessage}
                        onChange={(v) => {
                            setError("");
                            setAppMessage(v);
                        }}
                    />
                </div>
                <div data-color-mode="dark">
                    <label>Email Message</label>
                    <MDEditor
                        height={200}
                        value={message}
                        onChange={(v) => {
                            setError("");
                            setMessage(v);
                        }}
                    />
                </div>
                <div className="form-field">
                    <label>Notification Type</label>
                    <select value={notificationMessageType} onChange={(e) => setNotificationMessageType(e.currentTarget.value)}>
                        <option value="">All</option>
                        <option value="0">App Only</option>
                        <option value="1">Email Only</option>
                    </select>
                </div>
                <button onClick={sendNotificationAll} className="button -primary -small">
                    Notify All Active
                </button>
            </div>
            <div className="templates">
                <h4>Templates</h4>
                <NotificationTemplateList selectedId={id} templates={notificationTemplates || []} onClick={populateTemplate} />
            </div>
        </div>
    );
};

export default NotifyAdmin;
