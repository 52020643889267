import PaginatedStore from "./base/PaginatedStore";
import endpoints from "../services/endpoint";

export const DEFAULT_SORT = { name: "asc" };

const EMPLOYEE_STORAGE_KEY = `/api/teams${endpoints("teams_list")}`;

const { store, fetchList, reset, setFilters, setHiddenColumns, setLoading, setPageIndex, setPagingInfo, setPerPage, setSort } = PaginatedStore({
    key: "employees",
    label: "Employees",
    apiListUrl: EMPLOYEE_STORAGE_KEY,
    defaultSort: DEFAULT_SORT,
    storageKey: EMPLOYEE_STORAGE_KEY,
});

export const EmployeeStore = store;

export const fetchEmployees = fetchList;

export { reset, setFilters, setHiddenColumns, setLoading, setPageIndex, setPagingInfo, setPerPage, setSort };
