import React, { useEffect, useState } from "react";
import debounce from "debounce";
import classNames from "classnames";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import DashboardWidget from "./components/widget";
import Wizard from "$components/wizard";
import WizardReview from "./wizard/review";

import { setHelpContent } from "$stores/help";
import { HELP_DASHBOARD } from "../help/lib/help-contents";
import { closeModal, initiateModal } from "$stores/modal";
import {
    DashboardStore,
    buildCurrentMatrix,
    createCards,
    fetchUserCards,
    getCardMatrix,
    getTallestRow,
    removeCard,
    swapCard,
    translateIndexToDecimalPosition,
    unwatchDashboardCards,
    watchDashboardCards,
} from "$stores/dashboard";
import { handleAbort } from "$services/api";
import { useDocumentTitle } from "$utils/document-title";
import { UserStore } from "$stores/user";
import { stringMatch } from "$utils/check-match";
import { editDefaultTitle } from "./lib/helpers";

import "./dashboard.scss";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

const debouncedStartwebsocket = debounce(() => {
    watchDashboardCards();
}, 1000);

const DashboardIndex = () => {
    useDocumentTitle("Dashboard");
    const navigate = useNavigate();
    const appInsights = useAppInsightsContext();
    const [deletePhase, setDeletePhase] = useState(false);
    const [dragging, setDragging] = useState(false);
    const user = UserStore.useState((s) => s);
    const { cards } = DashboardStore.useState((s) => {
        return { cards: s.cards };
    });

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const redirectPath = urlParams.get("path");
        if (redirectPath) {
            navigate(redirectPath);
        }

        document.addEventListener("mouseup", handleDrop);

        setHelpContent(HELP_DASHBOARD);

        debouncedStartwebsocket();

        document.addEventListener("keydown", handleKeyDown);
        document.addEventListener("keyup", handleKeyUp);

        return () => {
            document.removeEventListener("mouseup", handleDrop);

            unwatchDashboardCards();

            document.removeEventListener("keydown", handleKeyDown);
            document.removeEventListener("keyup", handleKeyUp);
        };
    }, []);

    useEffect(() => {
        const cardController = fetchUserCards(user.id);

        return () => {
            handleAbort([cardController]);
        };
    }, [user]);

    const handleKeyDown = (e) => {
        if (e.shiftKey === true && e.ctrlKey === true) {
            setDeletePhase(true);
        }
    };

    const handleKeyUp = (e) => {
        if (e.shiftKey === true && e.ctrlKey === true) {
            return;
        }
        setDeletePhase(false);
    };

    const handleDrag = (e, dragPosition) => {
        e.dataTransfer.setData("widgetDragPosition", JSON.stringify(dragPosition));
        setDragging(true);
    };
    const handleDrop = (e, dropPosition) => {
        if (e.dataTransfer) {
            const cardDragPosition = JSON.parse(e.dataTransfer.getData("widgetDragPosition"));
            // Check if viable drop location
            var viableDrop = false;

            if (cardDragPosition.rowSize === dropPosition.rowSize && cardDragPosition.columnSize === dropPosition.columnSize) {
                viableDrop = true;
            } else {
                const maxRow = getTallestRow(cards);
                const matrix = buildCurrentMatrix(cards);
                const openMatrix = matrix.reduce((arr, m, i) => {
                    if (m === false) {
                        arr.push(translateIndexToDecimalPosition(i));
                    }
                    return arr;
                }, []);
                const currentCardMatrix = getCardMatrix(cardDragPosition.columnPosition, cardDragPosition.columnSize, cardDragPosition.rowPosition, cardDragPosition.rowSize).filter((m) => m < maxRow);
                const potentialCardMatrix = getCardMatrix(dropPosition.columnPosition, cardDragPosition.columnSize, dropPosition.rowPosition, cardDragPosition.rowSize).filter(
                    (m) => m < maxRow && !currentCardMatrix.includes(m)
                );
                viableDrop = potentialCardMatrix.every((v) => openMatrix.includes(v)) ? true : false;
            }
            if (viableDrop) {
                if (!stringMatch(dropPosition, cardDragPosition)) {
                    // Swap index
                    swapCard(dropPosition, cardDragPosition, user.id);
                }
                if (appInsights) {
                    appInsights.trackEvent(
                        { name: "Dashboard Card - Move" },
                        {
                            label: "Dashboard Card Move",
                            data: `Move (Row ${dropPosition?.rowPosition}, Column ${dropPosition?.columnPosition})`,
                        }
                    );
                }
            } else {
                toast("Unable to move card to that position");
            }
        }
        setDragging(false);
    };

    const onCreateCard = (answers, filters) => {
        // Create Card
        //const firstNull = cards.indexOf(null);
        //const insertIndex = firstNull >= 1 ? firstNull : cards.length;

        const columnSize = answers[2].columnSize;
        const rowSize = answers[2].rowSize;
        var columnPosition = 1;
        var rowPosition = 1;

        const matrix = buildCurrentMatrix(cards.filter((c) => !c?.isFakeCard));

        const openMatrix = matrix.reduce((arr, m, i) => {
            if (m === false) {
                arr.push(translateIndexToDecimalPosition(i));
            }
            return arr;
        }, []);

        const maxRow = getTallestRow(cards);
        openMatrix.some((mI, i) => {
            //const position = translateIndexToPosition(i);
            const position = mI.toString().split(".");
            const cardMatrix = getCardMatrix(Number(position[1]), columnSize, Number(position[0]), rowSize).filter((m) => m < maxRow);
            if (cardMatrix.every((v) => openMatrix.includes(v))) {
                columnPosition = Number(position[1]);
                rowPosition = Number(position[0]);
                return true;
            }
        });

        const defaultTitle = answers[2]?.text || "";
        const useTitle = editDefaultTitle(defaultTitle, { userId: user.id, filters, chartType: answers[2]?.type });
        const newCard = {
            cardId: `card${new Date().getTime()}`,
            data: answers[2].defaultValue,
            title: useTitle,
            template: answers[2].template,
            chartType: answers[2]?.type,
            columnSize: columnSize,
            rowSize: rowSize,
            metrics: JSON.stringify(answers[2]?.metrics || []),
            filters: filters ? JSON.stringify(filters) : {},
            sort: JSON.stringify(answers[2]?.sort || {}),
            rootEndpoint: answers[1]?.rootEndpoint,
            columnPosition,
            rowPosition,
        };
        createCards([newCard], user.id);

        if (appInsights) {
            appInsights.trackEvent(
                { name: "Dashboard Card - Create" },
                {
                    label: useTitle,
                    template: answers[2].template,
                    chartType: answers[2]?.type,
                }
            );
        }

        //Close modal
        closeModal();
    };

    const addWidget = () => {
        if (deletePhase) {
            cards.map((c) => {
                if (c.id) {
                    deleteCard(c.id);
                }
            });
            return;
        }

        initiateModal(Wizard, { onComplete: onCreateCard, ReviewComp: WizardReview }, { analyticsLabel: "Add Dashboard Card" });

        if (appInsights) {
            appInsights.trackEvent(
                { name: "Dashboard Card - Wizard" },
                {
                    label: "Wizard Modal",
                    page: document.title.replace(" - Orgaimi", ""),
                }
            );
        }
    };

    const deleteCard = (id) => {
        removeCard(id, user.id);
        if (appInsights) {
            appInsights.trackEvent(
                { name: "Dashboard Card - Remove" },
                {
                    label: "Dashbboard Card Remove",
                    page: document.title.replace(" - Orgaimi", ""),
                }
            );
        }
    };

    return (
        <div id="home-dashboard">
            <div className="container">
                <div className="flex-header">
                    <h2>Dashboard</h2>
                    <button className={classNames("button  -small", { "-secondary": !deletePhase, "-primary": deletePhase })} onClick={addWidget}>
                        {deletePhase ? "Delete All Cards" : "Add Card"}
                    </button>
                </div>
                <div className="dashboard-cards -movable">
                    {cards.reduce((arr, card, i) => {
                        arr.push(
                            <DashboardWidget
                                key={card.id ? `card_${card.id}_${i}` : `${new Date().getTime()}_${i}`}
                                card={card}
                                tempIndex={i}
                                dragging={dragging}
                                handleDrop={handleDrop}
                                handleDrag={handleDrag}
                                removeCard={deleteCard}
                            />
                        );

                        return arr;
                    }, [])}
                </div>
            </div>
        </div>
    );
};

export default DashboardIndex;
