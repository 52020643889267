import React, { useEffect, useState } from "react";

import ChartPie from "$components/charts/pie";
import ClientPotentialProjects from "./components/potential-projects";
import ExternalLegend from "$components/charts/legend/external";
import PotentialProjects from "./components/top-potential-projects";
import ToggleSwitch from "$components/toggle-switch";

import { ClientDetailsStore } from "$stores/client-details";
import { useDocumentTitle } from "$utils/document-title";
import { ChartsStore } from "$stores/charts";
import { aggregateChildren, limitChartData } from "./lib/chart-helper";
import { getTerm } from "$stores/dictionary";
import { setHelpContent } from "$stores/help";
import { HELP_CLIENT_GROWTH_POTENTIAL } from "../help/lib/help-contents";
import { scrollToName } from "$utils/scroll-to";
import { setFilters } from "$stores/clientPotentialProjects";

const buildToggleOptions = () => {
    return [
        { label: getTerm("averageRevenue"), value: 0 },
        { label: "Expansion Probability", value: 1 },
    ];
};

const ClientPotentialRevenue = () => {
    useDocumentTitle(`${getTerm("parentClient")} ${getTerm("growthPotential")}`);

    const { hasSubClients, clientDetails } = ClientDetailsStore.useState((s) => {
        return { hasSubClients: s.hasSubClients, clientDetails: s.clientDetails };
    });
    const { averageRevenueProjects, expansionProbabilityProjects } = ClientDetailsStore.useState((s) => s.projects);
    const { growthByIndustry, growthByServiceLine, growthByClient, growthByProjectType } = ChartsStore.useState((s) => {
        return {
            growthByProjectType: limitChartData(s.charts["growthByProjectType"], 9),
            growthByServiceLine: limitChartData(s.charts["growthByServiceLine"], 9),
            growthByIndustry: limitChartData(s.charts["growthByIndustry"], 9),
            growthByClient: limitChartData(s.charts["growthByClient"], 9),
        };
    });
    const [tab, setTab] = useState(0);
    const [toggleOptions] = useState(buildToggleOptions());

    useEffect(() => {
        setHelpContent(HELP_CLIENT_GROWTH_POTENTIAL);

        const hash = location.hash.split("?");
        if (hash[0]) {
            scrollToName(hash[0].replace("#", ""));
        }
    }, []);

    const tabChange = (value, index) => {
        setTab(index);
    };

    const setPotentialProjectFilter = (id, value) => {
        setFilters({
            [id]: value,
        });
        scrollToName("potential-projects");
    };

    return (
        <div>
            <h2>
                {getTerm("growthPotential")} {/* TODO: EM-ify */}
            </h2>
            {hasSubClients && (
                <div className="dashboard-cards">
                    <div className="card -span2">
                        <h4 className="txt -center -white">
                            {getTerm("growthPotential")} By {getTerm("industry")} & {getTerm("subClient")}
                        </h4>
                        <div className="chart-legend">
                            <ChartPie
                                charts={[
                                    {
                                        data: growthByIndustry,
                                        outerRadius: 105,
                                        innerRadius: 50,
                                        onClick: (data) => {
                                            setPotentialProjectFilter("industry", data.label);
                                        },
                                    },
                                    {
                                        data: aggregateChildren(growthByIndustry),
                                        outerRadius: 170,
                                        innerRadius: 115,
                                        onClick: (data) => {
                                            setPotentialProjectFilter("clientName", data.label);
                                        },
                                    },
                                ]}
                            />
                            <ExternalLegend
                                data={growthByIndustry}
                                onClick={(data) => {
                                    setPotentialProjectFilter("industry", data.label);
                                }}
                            />
                        </div>
                    </div>

                    <div className="card -span2">
                        <h4 className="txt -center -white">
                            {getTerm("growthPotential")} By {getTerm("serviceLine")} & {getTerm("projectType")}
                        </h4>
                        <div className="chart-legend">
                            <ChartPie
                                charts={[
                                    {
                                        data: growthByServiceLine,
                                        outerRadius: 105,
                                        innerRadius: 50,
                                        onClick: (data) => {
                                            setPotentialProjectFilter("serviceLine", [{ text: data.label, value: data.labelId }]);
                                        },
                                    },
                                    {
                                        data: aggregateChildren(growthByServiceLine),
                                        outerRadius: 170,
                                        innerRadius: 115,
                                        onClick: (data) => {
                                            setPotentialProjectFilter("name", data.label);
                                        },
                                    },
                                ]}
                            />
                            <ExternalLegend
                                data={growthByServiceLine}
                                onClick={(data) => {
                                    setPotentialProjectFilter("serviceLine", [{ text: data.label, value: data.labelId }]);
                                }}
                            />
                        </div>
                    </div>
                </div>
            )}
            {!hasSubClients && (
                <div className="dashboard-cards">
                    <div className="card -span2">
                        <h4 className="txt -center -white">
                            {getTerm("growthPotential")} By {getTerm("serviceLine")}
                        </h4>
                        <div className="chart-legend">
                            <ChartPie
                                charts={[
                                    {
                                        data: growthByServiceLine,
                                        outerRadius: 105,
                                        innerRadius: 50,
                                        onClick: (data) => {
                                            setPotentialProjectFilter("serviceLine", [{ text: data.label, value: data.labelId }]);
                                        },
                                    },
                                ]}
                            />
                            <ExternalLegend
                                data={growthByServiceLine}
                                onClick={(data) => {
                                    setPotentialProjectFilter("serviceLine", [{ text: data.label, value: data.labelId }]);
                                }}
                            />
                        </div>
                    </div>

                    <div className="card -span2">
                        <h4 className="txt -center -white">
                            {getTerm("growthPotential")} By {getTerm("projectType")}
                        </h4>
                        <div className="chart-legend">
                            <ChartPie
                                charts={[
                                    {
                                        data: growthByProjectType,
                                        outerRadius: 105,
                                        innerRadius: 50,
                                        onClick: (data) => {
                                            setPotentialProjectFilter("name", data.label);
                                        },
                                    },
                                ]}
                            />
                            <ExternalLegend
                                data={growthByProjectType}
                                onClick={(data) => {
                                    setPotentialProjectFilter("name", data.label);
                                }}
                            />
                        </div>
                    </div>
                </div>
            )}
            <br />
            <br />
            <br />
            <div className="flex-header">
                <h3 className="txt -uppercase -no-margin -tablet-center">
                    <em>Top 3</em> <span data-tooltip={getTerm("tooltipTopPotentialProjects")}>{getTerm("potentialProjects")}</span>
                </h3>
                <ToggleSwitch options={toggleOptions} updateField={tabChange} selectedIndex={tab} analyticsLabel={`Top 3 ${getTerm("potentialProjects")}`} />
            </div>
            <PotentialProjects projects={tab === 0 ? averageRevenueProjects : expansionProbabilityProjects} type={tab === 0 ? "revenue" : "probability"} />
            <br />
            <br />
            <br />
            <h3 className="txt -uppercase -tablet-center">
                <em>All {getTerm("parentClient")}</em> <span data-tooltip={getTerm("tooltipTopPotentialProjects")}>{getTerm("potentialProjects")}</span>
            </h3>
            <br />
            {clientDetails?.id && (
                <div name="potential-projects">
                    <ClientPotentialProjects />
                </div>
            )}
        </div>
    );
};

export default ClientPotentialRevenue;
