import React, { useEffect, useState } from "react";

import ExternalLegend from "$components/charts/legend/external";
import ChartScatter from "$components/charts/scatter";
import SubClientList from "./components/subclients";
import ChartPie from "$components/charts/pie";
import Top5 from "$components/top5";

import { buildTop5SubclientTitles } from "./lib/constants";
import { ClientDetailsStore } from "$stores/client-details";
import { SVIScoreRevenueLabelTooltip } from "$components/charts/tooltips/svi-score-revenue-label";
import { ChartsStore } from "$stores/charts";
import { limitChartData } from "./lib/chart-helper";
import { setSelectedSubclient } from "$stores/subclients";
import { getTerm } from "$stores/dictionary";
import { setHelpContent } from "$stores/help";
import { HELP_CLIENT_SUBCLIENTS } from "../help/lib/help-contents";
import { scrollToName } from "$utils/scroll-to";

const ClientSubClients = () => {
    const [top5SubClientTitles] = useState(buildTop5SubclientTitles());
    const { chartScoreSviReveune, subClients, totalPercentOfRevenue } = ClientDetailsStore.useState((s) => {
        return { subClients: s.subClients, totalPercentOfRevenue: s.totalPercentOfRevenue, chartScoreSviReveune: s.chartScoreSviReveune };
    });
    const top5 = ClientDetailsStore.useState((s) => s.top5SubClients);
    const { t12TotalRevenueByClient, growthByClient } = ChartsStore.useState((c) => {
        return {
            t12TotalRevenueByClient: limitChartData(c.charts.t12TotalRevenueByClient, 9),
            growthByClient: limitChartData(c.charts.growthByClient, 9),
        };
    });

    const handleClick = (item) => {
        if (item.id) {
            window.location.hash = `#client_${item.id}`;
            setSelectedSubclient(item.id);
        }
    };

    useEffect(() => {
        setHelpContent(HELP_CLIENT_SUBCLIENTS);
    }, []);

    const handleSubClientClick = (data) => {
        // TODO: what if the client isn't on this page?
        setSelectedSubclient(data.labelId, data.label);
        scrollToName(`client_${data.labelId}`);
    };

    return (
        <div>
            <div className="flex-header">
                <h2 className="txt -uppercase -no-margin -tablet-center">
                    {getTerm("subClients")}
                    {/* TODO: EM-it */}
                </h2>
            </div>
            <div className="dashboard-cards">
                <div className="card -span2 -center">
                    <h4 className="txt -center -white">
                        <span data-tooltip={getTerm("tooltip12MonthRevenue")}>{getTerm("t12Revenue")}</span> {getTerm("subClient")}
                    </h4>
                    <div>
                        <ChartPie
                            charts={[
                                {
                                    data: t12TotalRevenueByClient,
                                    outerRadius: 98,
                                    innerRadius: 30,
                                    onClick: handleSubClientClick,
                                },
                            ]}
                        />
                        <br />
                        <ExternalLegend data={t12TotalRevenueByClient} onClick={handleSubClientClick} />
                    </div>
                </div>
                <div className="card -span2 -center">
                    <h4 className="txt -center -white">
                        {getTerm("growthPotential")} {getTerm("subClient")}
                    </h4>
                    <div>
                        <ChartPie
                            charts={[
                                {
                                    data: growthByClient,
                                    outerRadius: 98,
                                    innerRadius: 30,
                                    onClick: handleSubClientClick,
                                },
                            ]}
                        />
                        <br />
                        <ExternalLegend data={growthByClient} onClick={handleSubClientClick} />
                    </div>
                </div>
            </div>
            <br />
            <div className="dashboard-cards">
                <div className="card -header">
                    <h2>
                        <em>Top</em> {getTerm("subClients")}
                    </h2>
                </div>
                {!_.isEmpty(top5) &&
                    Object.keys(top5SubClientTitles).map((key) => {
                        return (
                            <div key={key} className="card -span2">
                                {top5SubClientTitles[key]?.header && top5SubClientTitles[key].header()}
                                <Top5
                                    data={top5[key].map((t) => {
                                        const useKey = top5SubClientTitles[key].metrics && top5SubClientTitles[key].metrics.length > 0 ? top5SubClientTitles[key]?.metrics[0] : "value";
                                        return { ...t, [useKey]: t?.value };
                                    })}
                                    type={top5SubClientTitles[key]?.type}
                                    onClick={handleSubClientClick}
                                    metrics={top5SubClientTitles[key]?.metrics}
                                />
                            </div>
                        );
                    })}
            </div>
            <br />
            <br />
            <h3>Health Score vs Strategic Importance</h3>
            <div className="chart-scatter">
                <ChartScatter
                    data={chartScoreSviReveune}
                    yRange={[0, 10]}
                    xRange={[100, 700]}
                    dataKeyX="healthScore"
                    dataKeyY="svi"
                    dataKeyZ="revenue"
                    TooltipComponent={SVIScoreRevenueLabelTooltip}
                    handleClick={handleClick}
                />
            </div>

            {subClients.length > 0 && <SubClientList subClients={subClients} totalPercentOfRevenue={totalPercentOfRevenue} />}
        </div>
    );
};

export default ClientSubClients;
