import React from "react";

import { scrollToName } from "$utils/scroll-to";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { getTerm } from "$stores/dictionary";
import { AppContextStore } from "$stores/app-context";

const HelpNavigation = () => {
    const appInsights = useAppInsightsContext();
    const { siteKey } = AppContextStore.useState((a) => {
        return { siteKey: a.siteKey };
    });

    const handleClick = (e) => {
        e.preventDefault();
        const name = e.currentTarget.getAttribute("href");
        scrollToName(name.replace("#", ""), window);

        appInsights.trackEvent(
            { name: "Navigation" },
            {
                label: "Help Menu",
                page: document.title.replace(" - Orgaimi", ""),
            }
        );
    };

    return (
        <div className="docs-navigation">
            <ul>
                <li>
                    <a href="#overview" onClick={handleClick}>
                        Overview of Orgaimi
                    </a>
                </li>
                <li>
                    <a href="#use-cases" onClick={handleClick}>
                        Primary Use Cases
                    </a>
                </li>
                <li>
                    <a href="#potential-projects-core" onClick={handleClick}>
                        Core Concepts
                    </a>
                    <ul>
                        <li>
                            <a href="#potential-projects-core" onClick={handleClick}>
                                {getTerm("potentialProjects")}
                            </a>
                        </li>
                        <li>
                            <a href="#client-health-scores-core" onClick={handleClick}>
                                {getTerm("parentClient")} Health Scores
                            </a>
                        </li>
                        <li>
                            <a href="#strategic-value-core" onClick={handleClick}>
                                Strategic Value Index
                            </a>
                        </li>
                        <li>
                            <a href="#revenue-at-risk-core" onClick={handleClick}>
                                {getTerm("revenueAtRisk")}
                            </a>
                        </li>
                    </ul>
                </li>

                <li>
                    <a href="#login" onClick={handleClick}>
                        Accessing Orgaimi
                    </a>
                </li>
                <li>
                    <a href="#navigation" onClick={handleClick}>
                        Navigating the Dashboard
                    </a>
                </li>
                <li>
                    <a href="#dashboard" onClick={handleClick}>
                        Dashboard
                    </a>

                    <ul>
                        <li>
                            <a href="#dashboard" onClick={handleClick}>
                                Homepage
                            </a>
                        </li>
                        <li>
                            <a href="#growth-potential-panel" onClick={handleClick}>
                                {getTerm("growthPotential")} Panel
                            </a>
                        </li>
                    </ul>
                </li>
                <li>
                    <a href="#segments" onClick={handleClick}>
                        Segments
                    </a>
                    <ul>
                        <li>
                            <a href="#segment-summary" onClick={handleClick}>
                                Segment Summary
                            </a>
                        </li>
                    </ul>
                </li>
                {siteKey !== "faegredrinker" && (
                    <li>
                        <a href="#offices" onClick={handleClick}>
                            Offices
                        </a>
                        <ul>
                            <li>
                                <a href="#offices-map" onClick={handleClick}>
                                    Offices Map
                                </a>
                            </li>
                            <li>
                                <a href="#office-details" onClick={handleClick}>
                                    Office Details
                                </a>
                            </li>
                        </ul>
                    </li>
                )}

                <li>
                    <a href="#clients" onClick={handleClick}>
                        {getTerm("parentClients")}
                    </a>
                    <ul>
                        <li>
                            <a href="#client-list" onClick={handleClick}>
                                List
                            </a>
                        </li>
                        <li>
                            <a href="#client-page" onClick={handleClick}>
                                {getTerm("parentClient")} Dashboard
                            </a>
                            {/*<ul>
                                <li>
                                    <a href="#client-nav-header" onClick={handleClick}>
                                        Nav &amp; Header
                                    </a>
                                </li>
                                <li>
                                    <a href="#client-dashboard-overview" onClick={handleClick}>
                                        General Info
                                    </a>
                                </li>
                                <li>
                                    <a href="#client-dashboard-t12-revenue" onClick={handleClick}>
                                        {getTerm("t12Revenue")}
                                    </a>
                                </li>
                                <li>
                                    <a href="#client-dashboard-growth-potential" onClick={handleClick}>
                                       {getTerm("growthPotential")}
                                    </a>
                                </li>
                                <li>
                                    <a href="#potential-projects" onClick={handleClick}>
                                        {getTerm("potentialProjects")}
                                    </a>
                                </li>
                                <li>
                                    <a href="#recommendations" onClick={handleClick}>
                                        Recommendations
                                    </a>
                                </li>
                                <li>
                                    <a href="#client-details" onClick={handleClick}>
                                        {getTerm("parentClient")} Details
                                    </a>
                                </li>
                                <li>
                                    <a href="#scores" onClick={handleClick}>
                                        Scores
                                    </a>
                                </li>
                            </ul>*/}
                        </li>
                        <li>
                            <a href="#client-subclients" onClick={handleClick}>
                                {getTerm("subClients")}
                            </a>
                            {/*<ul>
                                <li>
                                    <a href="#client-subclient-charts" onClick={handleClick}>
                                        Charts
                                    </a>
                                </li>
                                <li>
                                    <a href="#client-subclient-top5" onClick={handleClick}>
                                        Top 5
                                    </a>
                                </li>
                                <li>
                                    <a href="#client-subclient-health-vs-importance" onClick={handleClick}>
                                        Health Score vs Strategic Importance
                                    </a>
                                </li>
                                <li>
                                    <a href="#client-subclient-all" onClick={handleClick}>
                                        All {getTerm("subClients")}
                                    </a>
                                </li>
                            </ul>*/}
                        </li>
                        <li>
                            <a href="#client-growth-potential" onClick={handleClick}>
                                {getTerm("growthPotential")}
                            </a>
                            {/*<ul>
                                <li>
                                    <a href="#client-growth-potential-charts" onClick={handleClick}>
                                        Charts
                                    </a>
                                </li>
                                <li>
                                    <a href="#client-growth-potential-top-projects" onClick={handleClick}>
                                        Top 3 Projects
                                    </a>
                                </li>
                            </ul>*/}
                        </li>
                        <li>
                            <a href="#client-recommendations" onClick={handleClick}>
                                Recommendations
                            </a>
                        </li>
                        <li>
                            <a href="#client-scores" onClick={handleClick}>
                                Scores
                            </a>
                            {/*<ul>
                                <li>
                                    <a href="#client-scores-overall" onClick={handleClick}>
                                        Overall
                                    </a>
                                </li>
                                <li>
                                    <a href="#client-growth-potential-top-projects" onClick={handleClick}>
                                        {getTerm("parentClient")} / {getTerm("subClient")} Themes & Scores
                                    </a>
                                </li>
                            </ul>*/}
                        </li>
                        <li>
                            <a href="#client-existing-projects" onClick={handleClick}>
                                {getTerm("existingProjects")}
                            </a>
                            {/*<ul>
                                <li>
                                    <a href="#client-existing-projects-charts" onClick={handleClick}>
                                        {getTerm("t12Revenue")}
                                    </a>
                                </li>
                                <li>
                                    <a href="#client-existing-projects-counts" onClick={handleClick}>
                                        {getTerm("existingProject")} Counts
                                    </a>
                                </li>
                                <li>
                                    <a href="#client-existing-projects-list" onClick={handleClick}>
                                        {getTerm("existingProjects")} List
                                    </a>
                                </li>
                            </ul>*/}
                        </li>
                    </ul>
                </li>
                <li>
                    <a href="#potential-projects" onClick={handleClick}>
                        {getTerm("potentialProjects")}
                    </a>
                    <ul>
                        <li>
                            <a href="potential-projects-list" onClick={handleClick}>
                                List
                            </a>
                        </li>
                        <li>
                            <a href="potential-projects-detail" onClick={handleClick}>
                                Detail
                            </a>
                        </li>
                    </ul>
                </li>
                <li>
                    <a href="#existing-projects" onClick={handleClick}>
                        {getTerm("existingProjects")}
                    </a>
                    <ul>
                        <li>
                            <a href="existing-projects-list" onClick={handleClick}>
                                List
                            </a>
                        </li>
                        <li>
                            <a href="existing-projects-detail" onClick={handleClick}>
                                Detail
                            </a>
                        </li>
                    </ul>
                </li>
                <li>
                    <a href="#team-dashboard" onClick={handleClick}>
                        Team
                    </a>

                    <ul>
                        <li>
                            <a href="team-dashboard" onClick={handleClick}>
                                Dashboard
                            </a>
                        </li>
                        <li>
                            <a href="team-past-work" onClick={handleClick}>
                                Past Work
                            </a>
                        </li>
                        <li>
                            <a href="team-managing" onClick={handleClick}>
                                Managing
                            </a>
                        </li>
                        <li>
                            <a href="team-experience" onClick={handleClick}>
                                Experience
                            </a>
                        </li>
                    </ul>
                </li>
                <li>
                    <a href="#overview-panel" onClick={handleClick}>
                        Overview Panel
                    </a>
                </li>
                <li>
                    <a href="#filters" onClick={handleClick}>
                        Filters
                    </a>
                </li>
                {/*<li>
                            <a href="#share-modal" onClick={handleClick}>
                                Share Modal
                            </a>
                        </li>*/}
                <li>
                    <a href="#key-terms" onClick={handleClick}>
                        Key Terms
                    </a>
                </li>
                <li>
                    <a href="#key-contacts" onClick={handleClick}>
                        Key Contacts
                    </a>
                </li>
            </ul>
        </div>
    );
};

export default HelpNavigation;
