import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faTrash } from "@fortawesome/free-solid-svg-icons";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";

import CustomFilters from "../wizard/custom-filters";
import DiscardDialog from "$components/modal/discard-dialog";

import { DashboardStore, fetchCardData, updateCardFilters } from "$stores/dashboard";
import _, { isEmpty } from "lodash";
import { handleAbort } from "$services/api";
import { getComponentByCardType, getFiltersByTemplate, getLinkTmeplate, getPersonalizedFilterByTemplate, getTemplateDataFormatter, hasEditableTitle } from "../lib/helpers";
import { AppContextStore } from "$stores/app-context";
import { mapByKey } from "$utils/mapping";
import { UserStore } from "$stores/user";
import { stringMatch } from "$utils/check-match";
import { Checkbox } from "$components/form-inputs/plain-inputs";
import { dialogConfirm } from "$stores/modal";
import { Input } from "$components/form-inputs/inputs";
import { toast } from "react-toastify";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

const TITLE_CHARACTER_LIMIT = 100;

const checkPersonlizedFilter = (filters, personalFilter) => {
    if (personalFilter && filters.hasOwnProperty(personalFilter.apiKey)) {
        return true;
    }
};

const DashboardWidget = ({ card, dragging, handleDrag, handleDrop, removeCard }) => {
    const appInsights = useAppInsightsContext();
    const { id, columnPosition, columnSize, rowPosition, rowSize } = card;
    const data = DashboardStore.useState((s) => {
        return s.cardData[id] || [];
    });
    const [editMode, setEditMode] = useState(false);
    const personalFilter = getPersonalizedFilterByTemplate(card.template);
    const [hasPersonalizedFilter, setHasPersonalizedFilter] = useState(checkPersonlizedFilter(card.filters, personalFilter));

    const user = UserStore.useState((s) => s);
    const [title, setTitle] = useState(card.title || "");
    const [filters, setFilters] = useState({ ...card.filters });
    const [isDirty, setIsDirty] = useState(false);
    const filterMenu = AppContextStore.useState((f) => mapByKey(f.filterMenu, "apiKey") || {});
    const [customFilters, setCustomFilters] = useState(getFiltersByTemplate(card.template, filterMenu));
    const Comp = getComponentByCardType(card.type);

    useEffect(() => {
        setCustomFilters(getFiltersByTemplate(card.template, filterMenu));
    }, [card.template, filterMenu]);

    useEffect(() => {
        // TODO: Need a better way to update when changes happen
        if (card.id && card.rootEndpoint && (isEmpty(data) || card.length < 0) && card.type !== "contact") {
            const cardDataController = fetchCardData(card.id, card.rootEndpoint, card.filters, card.sort, getTemplateDataFormatter(card.template));
            return () => {
                handleAbort([cardDataController]);
            };
        }
    }, [card.id, data, card.filters, card.sort, card.rootEndpoint]);

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
        e.dataTransfer.dropEffect = "move";
        e.currentTarget.classList.add("-drop-active");
    };
    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        e.currentTarget.classList.remove("-drop-active");
    };

    const editCard = async () => {
        if (editMode && isDirty) {
            const confirmSave = await dialogConfirm({
                Component: DiscardDialog,
                data: {
                    message: <p className="txt -center">Are you sure you want to discard you changes?</p>,
                },
                settings: {
                    type: "confirm",
                },
            });
            if (!confirmSave) {
                return false;
            }
        }
        setTitle(card.title);
        setFilters({ ...card.filters });
        setEditMode(!editMode);

        if (appInsights) {
            if (!editMode) {
                appInsights.trackEvent(
                    { name: "Dashboard Card - Edit Mode" },
                    {
                        label: "Edit Mode Button",
                        page: document.title.replace(" - Orgaimi", ""),
                    }
                );
            }
        }
    };

    const updateFilters = (filterKey, filterValue) => {
        const newFilters = { ...filters };
        if (!filterValue || (_.isArray(filterValue) && filterValue.length === 0)) {
            delete newFilters[filterKey];
        } else {
            newFilters[filterKey] = filterValue;
        }

        setFilters(newFilters);
        if (!stringMatch(card.filters, newFilters)) {
            setIsDirty(true);
        } else {
            setIsDirty(false);
        }
    };

    const saveFilters = () => {
        if (title.length > TITLE_CHARACTER_LIMIT) {
            toast("Card title is too long");
            return false;
        }
        if (isDirty) {
            const newCard = { ...card };
            newCard.title = title;
            updateCardFilters({ card: newCard, filters, userId: user.id });
            setIsDirty(false);
            setEditMode(false);
        }

        if (appInsights) {
            appInsights.trackEvent(
                { name: "Dashboard Card - Edit Save" },
                {
                    label: "Save Edits Button",
                    page: document.title.replace(" - Orgaimi", ""),
                }
            );
        }
    };

    const handlePersonalizedSelect = (key, value) => {
        if (hasPersonalizedFilter !== value) {
            if (value === true) {
                updateFilters(personalFilter.apiKey, { value: user.id, text: user?.displayName });
                setHasPersonalizedFilter(true);
            } else {
                updateFilters(personalFilter.apiKey, "");
                setHasPersonalizedFilter(false);
            }
        }
    };

    const handleTitleChange = (key, value) => {
        setTitle(value);
        if (value !== card.title) {
            setIsDirty(true);
        } else {
            setIsDirty(false);
        }
    };

    const characterCount = title.length;
    const charactersLeft = TITLE_CHARACTER_LIMIT - characterCount;

    return (
        <div
            draggable={!card.notDraggable && !editMode && !card.isFakeCard}
            className={classNames("card -border-light", { "-drop-zone": dragging, "-edit": editMode }, card.cssClass)}
            style={{ gridColumn: `${columnPosition} / span ${columnSize || 1}`, gridRow: `${rowPosition} / span ${rowSize || 1}` }}
            onDragStart={!card.notDraggable ? (e) => handleDrag(e, { id, columnPosition, columnSize, rowPosition, rowSize }) : () => {}}
            onDrop={(e) => handleDrop(e, { id, columnPosition, columnSize, rowPosition, rowSize })}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
        >
            {!card.notDraggable && (
                <div className="flip-container">
                    <div className="front">
                        {!card.isFakeCard && (
                            <button className="button -text btn-edit" onClick={editCard}>
                                <FontAwesomeIcon icon={faPencil} />
                            </button>
                        )}
                        <Comp id={card.id} data={data} metrics={card.metrics} title={card.title} linkTemplate={getLinkTmeplate(card.template)} filters={card.filters} sort={card.sort} rootEndpoint={card.rootEndpoint} />
                    </div>
                    <div className="back">
                        <div className="button-group edit-mode-controls">
                            <button
                                className="button -text txt -white"
                                onClick={() => {
                                    removeCard(id);
                                }}
                            >
                                <FontAwesomeIcon icon={faTrash} />
                            </button>
                            {(hasEditableTitle(card.type) || !_.isEmpty(customFilters) || !_.isEmpty(personalFilter)) && (
                                <button className={classNames("button -micro", { "-disabled": !isDirty })} onClick={saveFilters}>
                                    Save
                                </button>
                            )}
                            <button className="button -text txt -white" onClick={editCard}>
                                <FontAwesomeIcon icon={faTimesCircle} />
                            </button>
                        </div>

                        <div>
                            {hasEditableTitle(card.type) && (
                                <div className="edit-title">
                                    {characterCount >= TITLE_CHARACTER_LIMIT - 30 && <h5 className={classNames("character-count", { "-error": charactersLeft < 0 })}>{`${charactersLeft} characters remaining`}</h5>}

                                    <Input id={`edit_title_${card.id}`} label="Title" value={title} updateOnChange={handleTitleChange} className="-match-select" />
                                </div>
                            )}
                            <CustomFilters filterableOptions={customFilters || []} filters={card.filters} updateFilters={updateFilters} />
                            <div>
                                {personalFilter?.text && personalFilter.employeeTypes && personalFilter.employeeTypes.includes(user.employeeType) && (
                                    <div className="button-group -left">
                                        <Checkbox name={`card-personalize_${card.id}`} label={personalFilter?.text} value={hasPersonalizedFilter} updateOnChange={handlePersonalizedSelect} />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {/*!!card.notDraggable && `${rowPosition + columnPosition / 10}`*/}
        </div>
    );
};

export default DashboardWidget;
