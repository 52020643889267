import React from "react";

import FractionValue from "$components/map/map-panel/fraction-value";
import ScoreBubble from "$components/score-bubble";

import { checkIsHealthyByChange, getHealthColorClass } from "$utils/health-helper";
import { getTerm } from "$stores/dictionary";

const ScoresPanel = ({ office, data }) => {
    const healthClass = getHealthColorClass(data.averageHealthScore);
    const hasData = !!data.averageHealthScore;
    const isHealthy = checkIsHealthyByChange(data.averageHealthScoreChange);

    return (
        <div>
            <h3 data-tooltip={getTerm("tooltipHealthScore")}>Health Scores</h3>
            <p className="office-info">
                {office.officeName} | {office.officeManagingPartner}
            </p>
            <div>
                {!hasData && <p>No data available</p>}
                {hasData && (
                    <>
                        <h1 className={healthClass}>{data.averageHealthScore}</h1>
                        <h4>Current Average Score</h4>
                        <br />
                        <div className="chart-item">
                            <ScoreBubble healthy={isHealthy} arrow={true} graph={false} value={data.averageHealthScoreChange} postSup={"%"} />
                            <h4>Score Change</h4>
                        </div>
                        <br />
                        <div className="chart-item">
                            <ScoreBubble modifierClassNames="-title" arrow={false} graph={false} value={<FractionValue value={office.relativeFilterRanking} valueOf={data.officesTotal} />} />
                            <h4>Ranking of Offices Shown</h4>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default ScoresPanel;
