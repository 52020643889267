import { Store } from "pullstate";
import api from "$services/api";

const initialState = {
    projectExpertFlags: [],
    potentialProjectFlags: [],
};

export const FlagsStore = new Store(initialState);

export const fetchPotentialProjectFlags = async (projectId, page, limit) => {
    let queryString = `?page=${page}&limit=${limit}`;
    queryString += projectId ? `&projectId=${projectId}` : "";

    try {
        const response = await api.get(`/api/admin/flags/projects${queryString}`);
        FlagsStore.update((s) => {
            s.potentialProjectFlags = response;
        });
    } catch {}
};

export const deletePotentialProjectFlag = async (id) => {
    const response = await api.delete(`/api/admin/flags/projects/${id}`);
    return response;
};

export const fetchProjectExpertFlags = async (projectId, page, limit) => {
    let queryString = `?page=${page}&limit=${limit}`;
    queryString += projectId ? `&projectId=${projectId}` : "";
    try {
        const response = await api.get(`/api/admin/flags/project-experts${queryString}`);
        FlagsStore.update((s) => {
            s.projectExpertFlags = response;
        });
    } catch {}
};

export const deleteProjectExpertFlag = async (projectKey, flaggedEmployeeId, userId) => {
    const response = await api.delete(`/api/admin/flags/project-experts?projectKey=${projectKey}&flaggedEmployeeId=${flaggedEmployeeId}&userId=${userId}`);
    return response;
};
