import React, { useEffect } from "react";

import TeamPotentialProjectList from "./components/potential-projects";

import { EmployeeDetailsStore } from "$stores/employee-details";
import { useDocumentTitle } from "$utils/document-title";
import { getTerm } from "$stores/dictionary";
import { AppContextStore } from "$stores/app-context";
import { setHelpContent } from "$stores/help";
import { HELP_TEAM_EXPERIENCE } from "../help/lib/help-contents";

const TeamExperience = () => {
    useDocumentTitle("Employee Experience");
    const { employeeId } = EmployeeDetailsStore.useState((s) => {
        return { employeeId: s.employeeDetails?.id };
    });

    useEffect(() => {
        setHelpContent(HELP_TEAM_EXPERIENCE);
    }, []);

    return (
        <div>
            <h1>
                <em>Experience</em>
            </h1>
            <p>
                These are the {getTerm("potentialProjects")} that this person has been identified as an Experienced {getTerm("teamMember")} on.
            </p>
            <TeamPotentialProjectList overrideFilters={{ projectPerson: employeeId }} suppressGlobalFilters={true} />
        </div>
    );
};

export default TeamExperience;
