import PaginatedStore from "./base/PaginatedStore";

export const DEFAULT_SORT = { name: "asc" };
const CLIENT_STORAGE_KEY = "/api/projects/existing";

const { store, fetchList, reset, setFilters, setHiddenColumns, setLoading, setPageIndex, setPagingInfo, setPerPage, setSort } = PaginatedStore({
    key: "clientExistingProjects",
    label: "Client Existing Projects",
    apiListUrl: CLIENT_STORAGE_KEY,
    defaultSort: DEFAULT_SORT,
    storageKey: "client_" + CLIENT_STORAGE_KEY,
});

export const ClientExistingProjectsStore = store;

export const fetchClientExistingProjects = fetchList;

export { reset, setFilters, setHiddenColumns, setLoading, setPageIndex, setPagingInfo, setPerPage, setSort };
