import React from "react";
import { useNavigate } from "react-router-dom";

import TableLoader from "$components/table/table-loader";

import { ClientStore, fetchClients, reset, setFilters, setHiddenColumns, setPageIndex, setPagingInfo, setPerPage, setSort } from "$stores/clients";
import { buildDefaultColumns } from "./columns";
import { mapClientToTableData } from "./mapper";
import { AppContextStore } from "$stores/app-context";
import { mapByKey } from "$utils/mapping";
import { usePaginatedStoreWrapper } from "$utils/hooks/usePaginatedStore";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { getTerm } from "$stores/dictionary";

const ClientList = () => {
    const navigate = useNavigate();
    const appInsights = useAppInsightsContext();
    const { filterMenu, visibleColumns } = AppContextStore.useState((a) => {
        return {
            filterMenu: mapByKey(a.filterMenu, "apiKey") || {},
            visibleColumns: a.siteConfig?.clientGroupListColumns || null,
        };
    });

    const { clients, filters, globalFilterRanges, hiddenColumns, isLoading, isSortAsc, pageCount, pageIndex, pageSize, sort } = usePaginatedStoreWrapper(ClientStore, {
        fetchList: fetchClients,
        reset: reset,
        setPagingInfo: setPagingInfo,
    });

    const rowClick = (row) => {
        navigate(`/client/${row.key}`);
        appInsights.trackEvent(
            { name: `Navigation ${getTerm("parentClient")} List` },
            {
                label: row.id,
                page: document.title.replace(" - Orgaimi", ""),
            }
        );
    };

    const data = clients.map(mapClientToTableData);
    return (
        <TableLoader
            id="client-list"
            allowFilter={true}
            columns={buildDefaultColumns({ visibleColumns, globalFilterRanges: globalFilterRanges, filterOptions: filterMenu })}
            containerClass="client-list"
            data={data}
            filters={filters}
            hiddenColumns={hiddenColumns}
            isLoading={isLoading}
            isSortAsc={isSortAsc}
            onRowClick={rowClick}
            pageCount={pageCount}
            pageIndex={pageIndex}
            pageSize={pageSize}
            setFilters={setFilters}
            setHiddenColumns={setHiddenColumns}
            setPageIndex={setPageIndex}
            setPerPage={setPerPage}
            setSort={setSort}
            sort={sort}
        />
    );
};

export default ClientList;
