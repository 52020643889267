import React from "react";
import classNames from "classnames";
import _ from "lodash";

import { buildRangeText } from "$components/form-inputs/multi-range-slider/lib/range-helper";
import { getKeyMetaData } from "src/pages/dashboard/lib/helpers";

const FilterSummary = ({ filters, cssClass }) => {
    const modifiedFilters = { ...filters };
    // Handle SVIRange
    if (modifiedFilters["sviRangeMin"] || modifiedFilters["sviRangeMax"]) {
        modifiedFilters["sviRange"] = buildRangeText(modifiedFilters["sviRangeMin"], modifiedFilters["sviRangeMax"]);
        delete modifiedFilters.sviRangeMin;
        delete modifiedFilters.sviRangeMax;
    }

    return (
        <div className={classNames("filter-summary", cssClass)}>
            {Object.keys(modifiedFilters).map((key, i) => {
                return (
                    <div key={`filter_${key}_${i}`} className="filter-item">
                        <h4>{getKeyMetaData(key)?.text}</h4>
                        <div className="values">
                            {_.isArray(modifiedFilters[key]) ? modifiedFilters[key].map((f) => f.text).join(", ") : typeof modifiedFilters[key] === "string" ? modifiedFilters[key] : "Unreadable"}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default FilterSummary;
