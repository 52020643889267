import React from "react";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

import ProfileIcon from "$components/profile-icon";

const ManagerBox = ({ imageUrl = "", isActive = true, name, employeeId, title }) => {
    const appInsights = useAppInsightsContext();
    const trackEmployeeClick = () => {
        appInsights.trackEvent(
            { name: "Nagivation - Team" },
            {
                label: employeeId,
                page: document.title.replace(" - Orgaimi", ""),
            }
        );
    };

    return (
        <div className="manager-box">
            <div className="manager">
                <a href={`/team/${employeeId}`} onClick={trackEmployeeClick}>
                    <ProfileIcon imageUrl={imageUrl} name={name} isActive={isActive} modifierClassNames={["-medium"]} />
                </a>
                <div>
                    <a href={`/team/${employeeId}`} onClick={trackEmployeeClick}>
                        <h4>{name}</h4>
                    </a>
                    <h5>{title}</h5>
                </div>
            </div>
        </div>
    );
};

export default ManagerBox;
