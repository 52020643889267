import React from "react";

import DateCell from "$components/table/cells/date.js";
import InitialsCell from "$components/table/cells/initials";
import LargeNumber from "$components/info-list/content/large-number";

import { abbrNum } from "$utils/number-helper";
import { getTerm } from "$stores/dictionary";
import { DateFilter, MultiRangeFilter, SelectColumnFilter } from "$components/table/components/filters/filter";
import CollapsingPills from "$components/table/cells/collapsing-pills";

export const buildClientProjectsColumns = ({ filterOptions, globalFilterRanges, visibleColumns }) => {
    var columns = [
        {
            Header: () => "Name",
            accessorKey: "name", // accessor is the "key" in the data
            id: "name", // id is used as a sort key (i.e., actual api name)
        },
        {
            Header: () => getTerm("projectType"),
            accessorKey: "projectType", // accessor is the "key" in the data
            id: "projectType", // id is used as a sort key (i.e., actual api name)
        },
        {
            Header: () => getTerm("projectTypeGroup"),
            accessorKey: "projectTypeGroup", // accessor is the "key" in the data
            id: "projectTypeGroup", // id is used as a sort key (i.e., actual api name)
            size: 120,
        },
        {
            Header: () => getTerm("industry"),
            accessorKey: "industry",
            size: 120,
            enableSorting: false,
            Cell: ({ data, value }) => {
                const newData = value ? [{ id: value, text: value }] : [];
                return <CollapsingPills data={newData} maxVisible={2} keyPrefix="industry" analyticsLabel={getTerm("industries")} />;
            },
        },
        {
            Header: () => getTerm("serviceLine"),
            accessorKey: "serviceLine",
            size: 120,
            enableSorting: false,
            Filter: SelectColumnFilter,
            filterOptions: { options: filterOptions?.practice?.values || [] },
            Cell: ({ data, value }) => {
                const newData = value ? [{ id: value, text: value }] : [];
                return <CollapsingPills data={newData} maxVisible={2} keyPrefix="serviceLine" analyticsLabel={getTerm("serviceLines")} />;
            },
        },
        {
            Header: () => getTerm("projectPIC"),
            accessorKey: "projectPic", // accessor is the "key" in the data
            id: "projectPicName", // id is used as a sort key (i.e., actual api name)
            Cell: ({ data, value }) => <InitialsCell isActive={data.projectPicIsActive} value={value} />,
        },
        {
            Header: () => getTerm("existingProjectStart"),
            accessorKey: "startDate", // accessor is the "key" in the data
            id: "startDate", // id is used as a sort key (i.e., actual api name)
            className: "",
            enableColumnFilter: false,
            Filter: DateFilter,
            filterOptions: { label: "Date" },
            sortDescFirst: true,
            Cell: ({ data, value }) => <DateCell value={value} />,
        },
        {
            Header: () => getTerm("existingProjectEnd"),
            accessorKey: "endDate", // accessor is the "key" in the data
            id: "endDate", // id is used as a sort key (i.e., actual api name)
            className: "",
            sortDescFirst: true,
            enableColumnFilter: false,
            Filter: DateFilter,
            filterOptions: { label: "Date" },
            Cell: ({ data, value }) => <DateCell value={value} noValue="Ongoing" />,
        },
        {
            Header: () => "Status",
            accessorKey: "status",
            id: "projectstatus",
            sortDescFirst: true,
            size: 100,
            Filter: SelectColumnFilter,
            filterOptions: { options: globalFilterRanges?.status || [] },
        },
        {
            Header: () => getTerm("revenueTD"),
            accessorKey: "revenueToDate", // accessor is the "key" in the data
            id: "revenueToDate", // id is used as a sort key (i.e., actual api name)
            sortDescFirst: true,
            Filter: MultiRangeFilter,
            filters: {
                min: globalFilterRanges?.revenueToDateMin ? Math.floor(globalFilterRanges?.revenueToDateMin) : "",
                max: globalFilterRanges?.revenueToDateMax ? Math.ceil(globalFilterRanges?.revenueToDateMax) : "",
                format: { isCurrency: true },
            },
            Cell: ({ data, value }) => {
                return <LargeNumber value={`$${abbrNum(value)}`} isHealthy={true} />;
            },
        },
    ];

    if (visibleColumns && visibleColumns.length > 0) {
        columns = columns.filter((c) => {
            return visibleColumns.includes(c.accessorKey);
        });
    }

    return columns;
};

export const buildSubclientProjectsColumns = (initialColumns = []) => {
    const subclientProjectsColumns = [...initialColumns];
    subclientProjectsColumns.splice(3, 0, {
        Header: () => getTerm("subClient"),
        accessorKey: "clientName",
        id: "clientName",
    });
    return subclientProjectsColumns;
};
