import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShareNodes } from "@fortawesome/free-solid-svg-icons";

import BillingByTeam from "../components/billingByTeam";
import InfoTable from "$components/info-table";
import DateInfoCell from "$components/info-table/cells/date-info-cell";
import LinkInfoCell from "$components/info-table/cells/link-info-cell";
import ProjectBilling from "../components/project-billing";
import ShareModal from "$components/modal/share-modal";

import { AppContextStore } from "$stores/app-context";
import { ProjectDetailsStore } from "$stores/projectDetails";
import { useDocumentTitle } from "$utils/document-title";
import { getTerm } from "$stores/dictionary";
import { initiateModal } from "$stores/modal";
import { setHelpContent } from "$stores/help";
import { HELP_EXISTING_PROJECT_DETAIL } from "src/pages/help/lib/help-contents";

const buildInfoPropertiesMap = (showProjectTypeGroup) => {
    return showProjectTypeGroup
        ? {
              clientName: { label: `${getTerm("parentClient")} Name`, colSpan: 2, Component: LinkInfoCell, additionalProps: { url: "/client/#{clientId}" } },
              projectType: { label: getTerm("projectType"), colSpan: 2 },
              clientPIC: { label: getTerm("subCrm"), Component: LinkInfoCell, colSpan: 1, additionalProps: { id: "#{crmId}", url: "/team/#{crmId}", isActive: "#{crmIsActive}", email: "#{crmEmail}" } },
              startDate: { label: getTerm("existingProjectStart"), Component: DateInfoCell },
              subClientName: { label: getTerm("subClient"), colSpan: 2 },
              projectTypeGroup: { label: getTerm("projectTypeGroup"), colSpan: 2 },
              projectPIC: { label: getTerm("projectPIC"), colSpan: 1, Component: LinkInfoCell, additionalProps: { id: "#{projectPicId}", url: "/team/#{projectPicId}", isActive: "#{projectPicIsActive}" } },
              endDate: { label: getTerm("existingProjectEnd"), Component: DateInfoCell, additionalProps: { noValue: "Ongoing" } },
              industry: { label: getTerm("industry"), colSpan: 2 },
              serviceLine: { label: getTerm("serviceLine"), colSpan: 2 },
              status: { label: "Status", colSpan: 2 },
          }
        : {
              clientName: { label: `${getTerm("parentClient")} Name`, colSpan: 2, Component: LinkInfoCell, additionalProps: { url: "/client/#{clientId}" } },
              clientPIC: { label: getTerm("subCrm"), Component: LinkInfoCell, colSpan: 2, additionalProps: { id: "#{crmId}", url: "/team/#{crmId}", isActive: "#{crmIsActive}", email: "#{crmEmail}" } },
              projectType: { label: getTerm("projectType"), colSpan: 2 },
              subClientName: { label: getTerm("subClient"), colSpan: 2 },
              projectPIC: { label: getTerm("projectPIC"), colSpan: 2, Component: LinkInfoCell, additionalProps: { url: "/team/#{projectPicId}", isActive: "#{projectPicIsActive}" } },
              status: { label: "Status", colSpan: 2 },
              industry: { label: getTerm("industry"), colSpan: 2 },
              serviceLine: { label: getTerm("serviceLine"), colSpan: 2 },
              startDate: { label: getTerm("existingProjectStart"), Component: DateInfoCell },
              endDate: { label: getTerm("existingProjectEnd"), Component: DateInfoCell, additionalProps: { noValue: "Ongoing" } },
          };
};

const ExistingProject = () => {
    useDocumentTitle(getTerm("existingProject"));
    const { billingHistory, currentProject } = ProjectDetailsStore.useState((s) => {
        return { billingHistory: s.billingHistory, currentProject: s.projectDetails };
    });
    const { enableShare } = AppContextStore.useState((a) => {
        return { enableShare: a.settings?.externalConnections?.share };
    });
    const [infoPropertiesMap] = useState(buildInfoPropertiesMap(!!currentProject?.projectTypeGroup));

    const triggerModal = (type) => {
        if (type === "share") {
            initiateModal(ShareModal, { type: "project/existing", analyticsLabel: getTerm("existingProject") }, { analyticsLabel: getTerm("existingProject") });
        }
    };

    useEffect(() => {
        setHelpContent(HELP_EXISTING_PROJECT_DETAIL);
    }, []);

    return (
        <div className="main-wrapper">
            <div className="page-content -shadow">
                <div className="content">
                    <div className="flex-header">
                        <h1 className="txt -uppercase -no-margin -tablet-center">
                            <em>{getTerm("existingProject")}:</em> {currentProject.name}
                        </h1>
                        <div className="project-links">
                            {enableShare && (
                                <button
                                    className="button -text"
                                    onClick={() => {
                                        triggerModal("share", true);
                                    }}
                                    title="Share"
                                >
                                    <FontAwesomeIcon icon={faShareNodes} />
                                </button>
                            )}
                        </div>
                    </div>

                    <InfoTable data={currentProject} dataMap={infoPropertiesMap} colCount={6} />

                    <BillingByTeam data={currentProject.team} revenue={currentProject.revenueToDate} hours={currentProject.totalHoursBilled} />
                    {billingHistory && billingHistory.length > 0 && (
                        <>
                            <h3>
                                {getTerm("existingProject")} Billing Over {billingHistory.length}-Months
                            </h3>
                            <ProjectBilling data={billingHistory} />
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ExistingProject;
