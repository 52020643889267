import DefaultDialog from "$components/modal/dialog";
import { Store } from "pullstate";

const initialState = {
    Component: null,
    data: {},
    isOpen: false,
    disableClose: false,
    analyticsLabel: "Modal",
    type: "",
};

export const ModalStore = new Store(initialState);

export const initiateModal = (Component, data, settings = {}) => {
    ModalStore.update((s) => {
        s.Component = Component;
        s.data = data;
        s.isOpen = true;

        if (settings?.analyticsLabel) {
            s.analyticsLabel = settings.analyticsLabel;
        }

        if (settings?.type) {
            s.type = `-${settings.type}`;
        }

        return s;
    });
};

export const dialogConfirm = async ({ Component = DefaultDialog, data, settings = {} }) => {
    return new Promise((resolve, reject) => {
        ModalStore.update((s) => {
            s.Component = Component;
            s.data = { ...data, resolve, reject };
            s.isOpen = true;
            s.disableClose = true;

            if (settings?.analyticsLabel) {
                s.analyticsLabel = settings.analyticsLabel;
            }

            if (settings?.type) {
                s.type = `-dialog -${settings.type}`;
            }

            return s;
        });
    });
};

export const closeModal = () => {
    ModalStore.update((s) => {
        s.isOpen = false;
        s.type = "";
        s.analyticsLabel = "";
        s.disableClose = false;
        return s;
    });
};
