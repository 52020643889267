import { Store } from "pullstate";
import api from "../services/api";
import logger from "../services/logger";

import { clearCache, persistValue } from "../services/local";
import { loginRequest } from "../services/azure-auth/config";
import { parseJwt } from "$services/azure-auth/security";

const initialUserState = {
    id: null,
    displayName: null,
    firstName: null,
    lastName: null,
    email: null,
    title: null,
    headshotUri: null,
    lastLogin: null,
    welcomeMessage: null,
    isAuthed: false,
    isAdmin: false,
    lastDataUpdate: null,
    hasLoaded: false,
    hasNoAccess: false,
    hasRequestedAccess: false,
    account: null,
    instance: null,
};

export const UserStore = new Store(initialUserState);

export const postToUserProfile = async () => {
    try {
        await api.post("/api/users/profile", {});
    } catch (ex) {
        logger.error(ex);
    }
};

export const getUserProfile = async (account) => {
    let user = { lastDataUpdate: Date.now(), profile: {}, hasNoAccess: false };
    try {
        let retrievedUser = await api.getFromCache("/api/users/profile");
        if (retrievedUser) {
            user = retrievedUser;
        }
    } catch (ex) {
        logger.error(ex);
        user.hasNoAccess = true;
        api.resetAxiosClient();
        user.profile = {
            fullName: account.name,
        };
    }
    const isAdmin = await isUserAdmin();
    const authedUser = { ...user.profile, lastLogin: null, lastDataUpdate: user.lastDataUpdate, hasLoaded: true, isAuthed: !user.hasNoAccess, hasNoAccess: user.hasNoAccess, isAdmin: isAdmin };
    return authedUser;
};

async function isUserAdmin() {
    const adminRoles = ["All.Admin"];
    let roles = [];
    try {
        let parsedToken = {};
        var token = UserStore.getRawState().userToken;
        parsedToken = parseJwt(token);
        if (parsedToken) {
            roles = parsedToken?.roles || [];
        }
    } catch {}
    return roles.includes(adminRoles[0]);
}

export const logout = async () => {
    await clearCache();
    await UserStore.update((s) => {
        return initialUserState;
    });
    return api.resetAxiosClient();
};

export const acquireFreshToken = async (account, instance) => {
    if (!account || !instance) {
        return;
    }

    const silentRequest = {
        ...loginRequest,
        account: account,
    };

    return new Promise((resolve, reject) => {
        instance
            .acquireTokenSilent(silentRequest)
            .then((response) => {
                // updateAccessToken(response.accessToken);
                return resolve(response.accessToken);
            })
            .catch(async () => {
                await instance.acquireTokenRedirect(silentRequest);
            });
    });
};

function RequestAccessToken(accounts, instance) {
    const request = {
        ...loginRequest,
        account: accounts[0],
    };

    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    return new Promise((resolve, reject) => {
        instance
            .acquireTokenSilent(request)
            .then(async (response) => {
                let parsedToken = {};
                try {
                    parsedToken = parseJwt(response.accessToken);
                } catch {}
                UserStore.update((user) => {
                    user.userToken = response.accessToken;
                    user.account = accounts[0];
                    user.instance = instance;
                });
                api.resetAxiosClient(); // reset the client to use the new token
                resolve();
            })
            .catch((e) => {
                reject("No access");
            });
    });
}

const fetchUserProfile = async (account, instance) => {
    let userProfile = {};
    try {
        userProfile = await getUserProfile(account);
        await UserStore.update((user) => {
            return { ...user, ...userProfile, displayName: userProfile.fullName, account: account, instance: instance };
        });
    } catch (ex) {
        logger.error(ex);
        await UserStore.update((user) => {
            return { ...user, ...userProfile, displayName: userProfile.fullName };
        });
    }

    logger.log(userProfile, "userProfile", account, "account", userProfile.id, "userProfile.id");

    if (!!userProfile.id || userProfile.id === 0) {
        try {
            await postToUserProfile();
        } catch {
            logger.error("Failed to post to user profile");
            return {};
        }
    }
};

export const testLoginAction = async (token) => {
    try {
        const readToken = parseJwt(token);
        // Writing an empty/bogus token to the cache to simulate a logged in user
        await persistValue(
            "user-token",
            token || "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c",
            readToken.exp
        );
        api.resetAxiosClient();

        const emailObj = readToken?.preferred_username ? { email: readToken?.preferred_username } : {};
        const userProfile = await getUserProfile({});
        await UserStore.update((user) => {
            return { ...userProfile, displayName: userProfile.fullName, ...emailObj };
        });
        return;
    } catch (ex) {
        console.error("Failed to login", ex);
    }
};

export const retrieveExistingUser = async (inProgress, accounts, instance, hasRequestedAccess) => {
    if (inProgress === "logout") {
        await UserStore.update((user) => {
            return { ...user, hasLoaded: true };
        });
        return;
    }

    if (inProgress === "startup" || inProgress === "handleRedirect") {
        return;
    }

    if (inProgress === "none") {
        const accountInfo = instance.getAllAccounts();
        const existingUser = accountInfo && accountInfo.length > 0;
        if (existingUser) {
            try {
                await RequestAccessToken(accountInfo, instance);
                const account = accountInfo[0] || {};
                await fetchUserProfile(account, instance);
                return;
            } catch (ex) {
                await UserStore.update((user) => {
                    return { ...user, hasLoaded: true };
                });
            }
        } else {
            await UserStore.update((user) => {
                return { ...user, hasLoaded: true };
            });
        }
    }
};
