import React from "react";
import { fetchProjectSnapshots, repairSnapshot } from "./store";

const ProjectSnapshotAdminIndex = () => {
    const [message, setMessage] = React.useState("");
    const [result, setResult] = React.useState("");
    const findBadSnapshots = async () => {
        const bad = await fetchProjectSnapshots();
        setMessage(`Found ${bad.length} bad snapshot(s)`);
        if (bad.length === 0) {
            setResult("Nothing to repair");
            return;
        }
        try {
            for (const snapshot of bad) {
                await repairSnapshot(snapshot.projectKey);
            }
            setResult("Successfully repaired snapshot(s)");
        } catch (e) {
            setResult("Error repairing snapshot(s)");
        }
    };

    return (
        <>
            <h1>Project Snapshot Maintenance</h1>
            <button className="button -secondary -small" onClick={findBadSnapshots}>
                Repair Bad Snapshots
            </button>
            <h3>{message}</h3>
            <h3>{result}</h3>
        </>
    );
};

export default ProjectSnapshotAdminIndex;
