import React from "react";

import Flags from "$components/flags";
import CollapsingPills from "$components/table/cells/collapsing-pills";

import { getTerm } from "$stores/dictionary";
import InitialsCell from "$components/table/cells/initials";

export const buildDefaultColumns = () => {
    return [
        {
            Header: () => "Name",
            accessorKey: "name", // accessor is the "key" in the data
            id: "name", // id is used as a sort key (i.e., actual api name)
            Cell: ({ data, value }) => {
                return (
                    <div>
                        <Flags data={[{ classnames: { "-independence": data.requiresIndependence }, label: "Check Independence" }]} />
                        <InitialsCell value={value} isActive={data.isActive} />
                    </div>
                );
            },
        },
        {
            Header: () => "Title",
            accessorKey: "title",
            id: "title",
        },
        {
            Header: () => "Office",
            accessorKey: "homeOffice",
            id: "homeOffice",
        },
        {
            Header: () => "Roles",
            accessorKey: "employeeMetadata",
            id: "employeeMetadata",
            enableSorting: false,
            size: 120,
            Cell: ({ data, value = [] }) => {
                return <CollapsingPills data={value} maxVisible={2} keyPrefix="topRoles" analyticsLabel="Roles" />;
            },
        },
        {
            Header: () => `Top  ${getTerm("industries")}`,
            accessorKey: "topIndustries",
            id: "topIndustries",
            enableSorting: false,
            enableColumnFilter: false,
            size: 120,
            Cell: ({ data, value = [] }) => {
                return <CollapsingPills data={value} maxVisible={2} keyPrefix="topIndustries" analyticsLabel={`Top ${getTerm("industries")}`} />;
            },
        },
        {
            Header: () => `Top ${getTerm("serviceLines")}`,
            accessorKey: "topServiceLines",
            id: "topServiceLines",
            enableSorting: false,
            enableColumnFilter: false,
            size: 120,
            Cell: ({ data, value = [] }) => {
                return <CollapsingPills data={value} maxVisible={2} keyPrefix="topServiceLines" analyticsLabel={`Top ${getTerm("serviceLines")}`} />;
            },
        },
        {
            Header: () => `Top  ${getTerm("projectTypes")}`,
            accessorKey: "topProjectTypes",
            id: "topProjectTypes",
            enableSorting: false,
            enableColumnFilter: false,
            size: 120,
            Cell: ({ data, value = [] }) => {
                return <CollapsingPills data={value} maxVisible={2} keyPrefix="topProjectTypes" analyticsLabel={`Top ${getTerm("projectTypes")}`} />;
            },
        },
        {
            Header: () => `Top ${getTerm("parentClients")}`,
            accessorKey: "topParentClients",
            id: "topParentClients",
            enableSorting: false,
            enableColumnFilter: false,
            size: 120,
            Cell: ({ data, value = [] }) => {
                return <CollapsingPills data={value} maxVisible={2} keyPrefix="topParentClients" analyticsLabel={`Top ${getTerm("parentClients")}`} />;
            },
        },
    ];
};
