import React, { useEffect, useState } from "react";

import Table from ".";

import { scrollToName } from "$utils/scroll-to";

const TableLoader = ({
    allowFilter,
    initialFilters,
    columns,
    containerClass,
    data,
    disablePersistPagingInfo,
    filters,
    hiddenColumns,
    manualFiltering = true,
    manualPagination = true,
    manualSortBy = true,
    pageIndex,
    pageSize,
    pageCount,
    isLoading,
    onRowClick,
    sort,
    id,
    isSortAsc,
    selectedData,
    setFilters,
    setHiddenColumns,
    setPageIndex,
    setPerPage,
    setSort,
    showPagnitaion = true,
}) => {
    const scrollToPage = (p) => {
        if (setPageIndex) {
            scrollToName(containerClass);
            setPageIndex(p);
        }
    };

    return (
        <div className={containerClass} name={containerClass}>
            <Table
                allowFilter={allowFilter}
                columns={columns}
                data={data}
                disablePersistPagingInfo={disablePersistPagingInfo}
                filters={filters}
                hiddenColumns={hiddenColumns}
                id={id}
                initialFilters={initialFilters}
                isLoading={isLoading}
                isSortAsc={isSortAsc}
                manualFiltering={manualFiltering}
                manualPagination={manualPagination}
                manualSortBy={manualSortBy}
                onRowClick={onRowClick}
                pageCount={pageCount}
                pageIndex={pageIndex}
                pageSize={pageSize}
                selectedData={selectedData}
                setFilters={setFilters}
                setHiddenColumns={setHiddenColumns}
                setPageIndex={scrollToPage}
                setPerPage={setPerPage}
                setSort={setSort}
                showPagination={showPagnitaion}
                sort={sort}
            />
        </div>
    );
};

export default TableLoader;
