import PaginatedStore from "./base/PaginatedStore";
import endpoints from "../services/endpoint";

export const DEFAULT_SORT = { name: "asc" };
//TODO: Endpoint
const CLIENT_GROUPS_MANAGED_CM_STORAGE_KEY = `/api/client-groups${endpoints("cm_managed_list")}`;

const { store, fetchList, reset, setFilters, setHiddenColumns, setLoading, setPageIndex, setPagingInfo, setPerPage, setSort } = PaginatedStore({
    key: "clientGroupsManagedCM",
    label: "Client Groups Managed CM",
    apiListUrl: CLIENT_GROUPS_MANAGED_CM_STORAGE_KEY,
    defaultSort: DEFAULT_SORT,
    persistPagingInfo: false,
    storageKey: CLIENT_GROUPS_MANAGED_CM_STORAGE_KEY,
});

export const ClientGroupsCMManagedStore = store;

export const fetchClientGroupsCMManaged = fetchList;

export { reset, setFilters, setHiddenColumns, setLoading, setPageIndex, setPagingInfo, setPerPage, setSort };
