import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShareNodes } from "@fortawesome/free-solid-svg-icons";

import MobileTeamNav from "./mobile-team-nav";
import ShareModal from "$components/modal/share-modal";

import { NavLink } from "react-router-dom";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { AppContextStore } from "$stores/app-context";
import { initiateModal } from "$stores/modal";
import { getTerm } from "$stores/dictionary";
import { EmployeeDetailsStore } from "$stores/employee-details";

const navLinks = (enableExperiencedTeamMembers) => {
    return [
        {
            text: "Dashboard",
            url: "",
        },
        {
            text: "Past Work",
            url: "/past-work",
        },
        {
            text: "Managing",
            url: "/managing",
            requiresManaged: true,
        },
        ...(enableExperiencedTeamMembers
            ? [
                  {
                      text: "Experience",
                      url: "/experience ",
                      requiresExperience: true,
                  },
              ]
            : []),
    ];
};

const TeamNav = ({ employeeId }) => {
    const appInsights = useAppInsightsContext();
    const basepath = `/team/${employeeId}`;
    const { clientCount, clientManagerCount, clientGroupCount, projectCount, potentialProjectCount } = EmployeeDetailsStore.useState((s) => s.employeeDetails);
    const { enableShare, enableExperiencedTeamMembers } = AppContextStore.useState((a) => {
        return { enableShare: a.settings?.externalConnections?.share, enableExperiencedTeamMembers: a.settings?.sections?.experiencedTeamMembers || false };
    });

    const trackEvent = (label) => {
        appInsights.trackEvent(
            { name: "Navigation - Team Detail" },
            {
                label: label,
                page: document.title.replace(" - Orgaimi", ""),
            }
        );
    };

    const triggerShareModal = () => {
        initiateModal(ShareModal, { analyticsLabel: getTerm("teamMember") }, { analyticsLabel: getTerm("teamMember") });
    };
    return (
        <div className="team-nav">
            <MobileTeamNav navLinks={navLinks(enableExperiencedTeamMembers)} basepath={basepath} />
            <div className="button-list -underline">
                {navLinks(enableExperiencedTeamMembers).map((l, i) => {
                    if (l.requiresManaged && clientGroupCount === 0 && clientManagerCount === 0 && clientCount === 0 && projectCount === 0) {
                        return null;
                    }
                    if (l.requiresExperience && potentialProjectCount === 0) {
                        return null;
                    }
                    return (
                        <NavLink
                            key={`team_nav_${i}`}
                            end
                            className={({ isActive }) => (isActive ? "-active" : undefined)}
                            to={`${basepath}${l.url}`}
                            onClick={() => {
                                trackEvent(l.text);
                            }}
                        >
                            {l.text}
                        </NavLink>
                    );
                })}
            </div>
            {enableShare && (
                <button className="button -text" onClick={triggerShareModal}>
                    Share <FontAwesomeIcon icon={faShareNodes} />
                </button>
            )}
        </div>
    );
};

export default TeamNav;
