import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort, faSortUp, faSortDown } from "@fortawesome/free-solid-svg-icons";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

const getNextSortValue = (value, sortDescFirst) => {
    if (sortDescFirst) {
        return !value ? "desc" : value === "desc" ? "asc" : null;
    } else {
        return !value ? "asc" : value === "asc" ? "desc" : null;
    }
};

const SortButton = ({ id, sortDescFirst, updateSort, value }) => {
    const appInsights = useAppInsightsContext();
    const nextSortValue = getNextSortValue(value, sortDescFirst);
    return (
        <span
            className="sort"
            onClick={(e) => {
                appInsights.trackEvent(
                    { name: "Table - Sort" },
                    {
                        label: id,
                        data: nextSortValue || "No Sort",
                        page: document.title.replace(" - Orgaimi", ""),
                    }
                );
                const persist = e.nativeEvent.shiftKey ? true : false;
                updateSort(id, nextSortValue, persist);
            }}
        >
            <FontAwesomeIcon icon={faSort} />
            {{
                asc: (
                    <div className="asc">
                        <FontAwesomeIcon icon={faSortUp} />
                    </div>
                ),
                desc: (
                    <div className="desc">
                        <FontAwesomeIcon icon={faSortDown} />
                    </div>
                ),
            }[value] ?? null}
        </span>
    );
};

export default SortButton;
