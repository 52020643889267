import React, { useState, useEffect } from "react";
import { CommentsStore, fetchComments, deleteComment } from "./store.js";
import debounce from "debounce";
import { format } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { LONG_DATE_FORMAT } from "$utils/date-helpers.js";

import ConfirmButton from "../../components/ConfirmButton.js";
import Table from "$components/table/index.js";
import usePagination from "../../hooks/usePagination";
import Pagination from "$components/table/components/pagination";

import "./comments.scss";

const debounceFetchComments = debounce(fetchComments, 500);

const commentsColumns = [
    {
        Header: () => "Id",
        accessorKey: "id", // accessor is the "key" in the data
        enableColumnFilter: false,
        id: "id", // id is used as a sort key (i.e., actual api name)
    },
    {
        Header: () => "Date",
        accessorKey: "commentDate", // accessor is the "key" in the data
        id: "commentDate", // id is used as a sort key (i.e., actual api name)
        enableColumnFilter: false,
        Cell: ({ data, value }) => {
            return format(new Date(value), LONG_DATE_FORMAT);
        },
    },
    {
        Header: () => "Project Id",
        accessorKey: "projectId", // accessor is the "key" in the data
        id: "projectId", // id is used as a sort key (i.e., actual api name)
        Cell: ({ data, value }) => {
            return <a href={`/project/${value}/potential`}>{value}</a>;
        },
    },
    {
        Header: () => "User",
        accessorKey: "displayName", // accessor is the "key" in the data
        id: "displayName", // id is used as a sort key (i.e., actual api name)
        enableColumnFilter: false,
    },
    {
        Header: () => "Comment",
        accessorKey: "commentText", // accessor is the "key" in the data
        id: "commentText", // id is used as a sort key (i.e., actual api name)
        enableColumnFilter: false,
    },
    {
        Header: () => "Actions",
        accessorKey: "id", // accessor is the "key" in the data
        id: "id", // id is used as a sort key (i.e., actual api name)
        enableSorting: false,
        enableColumnFilter: false,
        Cell: () => {
            return (
                <div>
                    <button className="button -text">
                        <FontAwesomeIcon icon={faTrash} />
                    </button>
                </div>
            );
        },
    },
];

const CommentsAdmin = () => {
    const comments = CommentsStore.useState((s) => s.comments);
    const [projectId, setProjectId] = React.useState("");
    const { page, setPage, limit, setLimit } = usePagination();
    const [sort, setSort] = useState({ id: "desc" });
    const [filters, setFilters] = useState([]);

    const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {
        debounceFetchComments(projectId, page, limit);
    }, [projectId, page, limit, sort, filters]);

    useEffect(() => {
        if (comments && comments.length > 0) {
            setTotalPages(Math.ceil(comments[0].totalRecordCount / limit));
        }
    }, [comments]);

    const manualSort = (s) => {
        setSort(s);
        //const sortedComments = sortData(comments, s);
        //setBillingData(sortedComments);
    };

    return (
        <div className="comments-admin">
            <h1>Comments</h1>
            <div>
                <div className="form-field">
                    <label>
                        <b className="txt -white">Filter by Project ID:</b>
                    </label>
                    <input type="text" value={projectId} onChange={(e) => setProjectId(e.currentTarget.value)} />
                </div>
            </div>
            <br />

            <Table allowFilter={true} data={comments} columns={commentsColumns} hiddenColumns={[]} sort={sort} setSort={manualSort} showPagination={false} />
            {/*<td>
                <ConfirmButton
                    onClick={() =>
                        deleteComment(comment.id).then(() => {
                            setTimeout(() => {
                                debounceFetchComments(projectId, page, limit);
                            }, 500);
                        })
                    }
                    text="Delete"
                />
            </td>*/}

            <Pagination
                pageIndex={page}
                pageCount={totalPages}
                pageSize={limit}
                setPageIndex={setPage}
                setPerPage={setLimit}
                canPreviousPage={page > 1}
                canNextPage={page < totalPages - 1}
                pageOptions={Array.from({ length: totalPages }, (x, i) => i)}
            />
        </div>
    );
};

export default CommentsAdmin;
