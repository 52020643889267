import React, { useState } from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faFlag, faMessage } from "@fortawesome/free-solid-svg-icons";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

import ProfileIcon from "$components/profile-icon";
import TeamFeedback from "$components/feedback/team-feedback";

import { buildChatUrl } from "$utils/chat-type";
import { getExpertInIcon } from "./lib/constants";
import { AppContextStore } from "$stores/app-context";
import { closeModal, initiateModal } from "$stores/modal";
import { getTerm } from "$stores/dictionary";

const TeamMember = ({ data, projectId, projectKey, showExpertIn, showFeedback = false, modifierClassNames = ["-small"] }) => {
    const appInsights = useAppInsightsContext();
    const expertTypes = AppContextStore.useState((f) => f.filters?.expertType || []);
    const { id, isActive = true, name, email, userFlag, expertType = [], title } = data;
    const [inactive, setInactive] = useState(userFlag);
    const { enableTeams } = AppContextStore.useState((a) => {
        return { enableTeams: a.settings?.externalConnections?.teams && isActive };
    });

    const triggerModal = (type) => {
        if (type === "feedback") {
            initiateModal(
                TeamFeedback,
                {
                    projectId: projectId,
                    projectKey: projectKey,
                    employeeId: id,
                    employeeName: name,
                    onSubmit: () => {
                        setInactive(true);
                        closeModal();
                    },
                },
                { analyticsLabel: `${getTerm("teamMember")} Feedback` }
            );
        }
    };

    const trackEmployeeClick = () => {
        appInsights.trackEvent(
            { name: "Navigate - Employee" },
            {
                data: id,
                label: "Link",
            }
        );
    };

    return (
        <div className={classNames("team-member", { "-inactive": inactive, "-hoverable": showFeedback || (data.isActive && email) })}>
            <div className="profile-icon-wrapper">
                <ProfileIcon name={name} isActive={isActive} modifierClassNames={modifierClassNames} />
                <div className="icon-menu">
                    {email && isActive && (
                        <a
                            href={buildChatUrl("email", email)}
                            target="_blank"
                            onClick={() => {
                                appInsights.trackEvent(
                                    { name: "Team Contact" },
                                    {
                                        data: id,
                                        label: "Email",
                                        page: document.title.replace(" - Orgaimi", ""),
                                    }
                                );
                            }}
                        >
                            <FontAwesomeIcon icon={faEnvelope} />
                        </a>
                    )}
                    {enableTeams && email && (
                        <a
                            href={buildChatUrl("teams", email)}
                            target="_blank"
                            onClick={() => {
                                appInsights.trackEvent(
                                    { name: "Team Contact" },
                                    {
                                        data: id,
                                        label: "Microsoft Teams",
                                        page: document.title.replace(" - Orgaimi", ""),
                                    }
                                );
                            }}
                        >
                            <FontAwesomeIcon icon={faMessage} />
                        </a>
                    )}
                    {!inactive && id && showFeedback && (
                        <button
                            className="button -text"
                            onClick={() => {
                                triggerModal("feedback", true);
                                appInsights.trackEvent(
                                    { name: "Feedback - Employee" },
                                    {
                                        data: id,
                                        label: "Feedback",
                                        page: document.title.replace(" - Orgaimi", ""),
                                    }
                                );
                            }}
                        >
                            <FontAwesomeIcon icon={faFlag} />
                        </button>
                    )}
                </div>
            </div>

            <h6>
                <a href={`/team/${id}`} onClick={trackEmployeeClick}>
                    {name}
                </a>
            </h6>
            {title && <h6 className="txt -gray">{title}</h6>}

            {showExpertIn && (
                <div className="expert-in">
                    {expertTypes.map((r) => {
                        const isExpert = expertType.some((t) => t.id.toString() === r.id.toString());
                        return (
                            <div key={`expertIn_${r.id}`} className={classNames({ "-active": isExpert })} data-tooltip={r.text}>
                                <span>
                                    <FontAwesomeIcon icon={getExpertInIcon(r.id).icon} />
                                </span>
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default TeamMember;
