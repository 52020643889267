import React, { useState } from "react";
import debounce from "debounce";
import { useNavigate } from "react-router-dom";

import TableLoader from "$components/table/table-loader.js";

import { buildDefaultColumns } from "./libs/columns.js";
import { EmployeeStore, fetchEmployees, reset, setFilters, setHiddenColumns, setPageIndex, setSort, setPagingInfo, setPerPage } from "$stores/employee-list.js";
import { usePaginatedStoreWrapper } from "$utils/hooks/usePaginatedStore.js";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

export const mapToTableData = (c) => {
    return {
        key: c.id,
        ...c,
    };
};

const EmployeeList = () => {
    const navigate = useNavigate();
    const appInsights = useAppInsightsContext();

    const [defaultColumns] = useState(buildDefaultColumns());

    const { employees, filters, hiddenColumns, isLoading, isSortAsc, pageCount, pageIndex, pageSize, sort } = usePaginatedStoreWrapper(EmployeeStore, {
        fetchList: fetchEmployees,
        reset: reset,
        setPagingInfo: setPagingInfo,
    });

    const rowClick = (row) => {
        navigate(`/team/${row.key}`);
        appInsights.trackEvent(
            { name: "Navigation - Team List" },
            {
                label: row.key,
                page: document.title.replace(" - Orgaimi", ""),
            }
        );
    };

    const data = employees.map(mapToTableData);

    const debouncedSetPagingInfo = debounce(setPagingInfo, 500);

    return (
        <TableLoader
            allowFilter={true}
            columns={defaultColumns}
            containerClass="team-list"
            data={data}
            filters={filters}
            hiddenColumns={hiddenColumns}
            isLoading={isLoading}
            isSortAsc={isSortAsc}
            onRowClick={rowClick}
            pageIndex={pageIndex}
            pageSize={pageSize}
            pageCount={pageCount}
            setFilters={setFilters}
            setHiddenColumns={setHiddenColumns}
            setPageIndex={setPageIndex}
            setPagingInfo={debouncedSetPagingInfo}
            setPerPage={setPerPage}
            setSort={setSort}
            sort={sort}
        />
    );
};

export default EmployeeList;
