import React from "react";
import classNames from "classnames";

import { AppContextStore } from "$stores/app-context";
import { dealProbabilityText } from "$utils/probability-text";

import "./probability.scss";

const ProbabilityBubble = ({ label, value, cssClasses = [] }) => {
    const { probabilityMatrix } = AppContextStore.useState((a) => {
        return { probabilityMatrix: a.siteConfig?.probabilityMatrix };
    });
    const probabilityText = dealProbabilityText(value, probabilityMatrix);
    return (
        <div className="probability-bubble">
            <div className="probability-circle-wrapper">
                <div className={classNames("probability-circle", `-${probabilityText.toLowerCase()}`, cssClasses)}>
                    <div>{probabilityText}</div>
                </div>
            </div>
            {label && <h5>{label}</h5>}
        </div>
    );
};

export default ProbabilityBubble;
