import React from "react";

import EmployeeList from "./list";

import { useDocumentTitle } from "$utils/document-title";

const Employees = () => {
    useDocumentTitle("Employee List");

    return (
        <div>
            <EmployeeList />
        </div>
    );
};

export default Employees;
