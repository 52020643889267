import React from "react";
import _ from "lodash";

import { Checkbox } from "$components/form-inputs/plain-inputs";

const TableSettingsModal = ({ columns, hiddenColumns, setHiddenColumns }) => {
    const handleClick = (name, value) => {
        setHiddenColumns(name, !value);
    };

    return (
        <div className="table-settings-modal">
            {setHiddenColumns ? (
                <>
                    <h3>
                        <em>Visible</em> Columns:
                    </h3>
                    <div>
                        {columns.map((c, i) => {
                            return (
                                <div key={`checkbox_${c.accessorKey}_${i}`}>
                                    <Checkbox name={c.accessorKey} label={c.Header()} value={!hiddenColumns.includes(c.accessorKey)} updateOnChange={handleClick} />
                                </div>
                            );
                        })}
                    </div>
                </>
            ) : (
                <h3>Settings are not currently available</h3>
            )}
        </div>
    );
};

export default TableSettingsModal;
