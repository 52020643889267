import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import classnames from "classnames";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../services/azure-auth/config";
import { Squash as Hamburger } from "hamburger-react";

import MainNavItem from "./components/main-nav-item";
import UserNav from "./components/user-nav";
import AppVersion from "./components/app-version";

import { buildMainLinks, PAGES_WITH_FILTER } from "./lib/constants";
import { UserStore } from "../../stores/user";
import { AppContextStore, setShowFilter } from "../../stores/app-context";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

import "./header.scss";

var menuTimeout = null;

const MainDropdown = ({ isOpen, closeDropdown }) => {
    const appInsights = useAppInsightsContext();
    const location = useLocation();
    const { instance } = useMsal();
    const user = UserStore.useState((s) => s);
    const trackEvent = (label) => {
        appInsights.trackEvent(
            { name: "Navigation - Main" },
            {
                label: label,
                page: document.title.replace(" - Orgaimi", ""),
            }
        );
    };
    const { sections } = AppContextStore.useState((a) => {
        return { sections: a.settings?.sections || {} };
    });

    const items = buildMainLinks(sections).map((l, i) => {
        return <MainNavItem key={`mainNav_${i}`} hasActivePath={location.pathname.startsWith(l.path)} closeDropdown={closeDropdown} trackEvent={trackEvent} path={l.path} label={l.label} />;
    });

    return (
        <div
            className={classnames("main-dropdown", { "-active": isOpen })}
            onMouseLeave={() => {
                clearTimeout(menuTimeout);
                menuTimeout = setTimeout(() => {
                    closeDropdown();
                }, 700);
            }}
            onMouseEnter={() => {
                clearTimeout(menuTimeout);
            }}
        >
            <ul>
                {items}
                {!user.isAuthed ? (
                    <li>
                        <a
                            href="#login"
                            onClick={(e) => {
                                e.preventDefault();
                                trackEvent("Login");
                                instance.loginRedirect(loginRequest);
                            }}
                        >
                            Login
                        </a>
                    </li>
                ) : (
                    <li>
                        <a
                            href="/logout"
                            onClick={() => {
                                trackEvent("Logout");
                            }}
                        >
                            Logout
                        </a>
                    </li>
                )}
            </ul>
        </div>
    );
};

const Header = () => {
    const [isOpen, setIsOpen] = useState(false);
    const user = UserStore.useState((s) => s);

    const location = useLocation();
    useEffect(() => {
        if (!location) {
            return;
        }
        setShowFilter(PAGES_WITH_FILTER.includes(location.pathname));
    }, [location]);

    const closeDropdown = () => {
        setIsOpen(false);
    };

    return (
        <div className="header">
            <div className="container">
                <div className="logo-wrapper">
                    <Hamburger size={25} toggled={isOpen} toggle={setIsOpen} />
                    <MainDropdown isOpen={isOpen} closeDropdown={closeDropdown} />
                    <div className="logo">
                        <a href="/">
                            <img alt="Orgaimi Logo" src="/images/logo-orgaimi.svg" />
                        </a>
                        <AppVersion />
                    </div>
                </div>
                {user.isAuthed && <UserNav />}
            </div>
        </div>
    );
};

export default Header;
