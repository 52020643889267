import React from "react";

import PotentialProjectList from "./list.js";

import { useDocumentTitle } from "$utils/document-title.js";

const PotentialProjects = () => {
    useDocumentTitle("Potential Project List");

    return (
        <div>
            <PotentialProjectList />
        </div>
    );
};

export default PotentialProjects;
