import React from "react";
import { setActiveEventTypeId } from "../../store.js";

const DefintionSelection = ({ activeMessages, list }) => {
    return (
        <div className="generic-nav">
            <div className="button-list -small">
                {list.map((item, index) => {
                    return (
                        <a
                            key={index}
                            href=""
                            onClick={(e) => {
                                e.preventDefault();
                                setActiveEventTypeId(item.id);
                            }}
                            className={activeMessages && activeMessages[0]?.eventTypeId === item.id ? "-active" : ""}
                        >
                            {item.name}
                        </a>
                    );
                })}
            </div>
        </div>
    );
};

export default DefintionSelection;
