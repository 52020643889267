import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

import TableLoader from "$components/table/table-loader.js";

import { buildClientProjectsColumns, buildSubclientProjectsColumns } from "./lib/columns.js";
import { ClientExistingProjectsStore, fetchClientExistingProjects, reset, setFilters, setHiddenColumns, setPageIndex, setPagingInfo, setPerPage, setSort } from "$stores/clientExistingProjects.js";
import { mapToTableData } from "src/pages/projects/existing/list.js";
import { ClientDetailsStore } from "$stores/client-details.js";
import { AppContextStore } from "$stores/app-context.js";
import { mapByKey } from "$utils/mapping.js";
import { usePaginatedStoreWrapper } from "$utils/hooks/usePaginatedStore.js";
import { getTerm } from "$stores/dictionary.js";

const ExistingProjects = () => {
    const appInsights = useAppInsightsContext();
    const navigate = useNavigate();
    const { filterMenu, visibleColumns } = AppContextStore.useState((f) => {
        return { filterMenu: mapByKey(f.filterMenu, "apiKey") || {}, visibleColumns: f.siteConfig?.existingProjectListColumns || null };
    });

    const [clientProjectsColumns, setClientProjectsColumns] = useState([]);
    const [subclientProjectsColumns, setSubclientProjectsColumns] = useState([]);

    const hasSubClients = ClientDetailsStore.useState((s) => s.hasSubClients);
    const { clientExistingProjects, filters, globalFilterRanges, hiddenColumns, isLoading, isSortAsc, pageCount, pageIndex, pageSize, sort } = usePaginatedStoreWrapper(ClientExistingProjectsStore, {
        fetchList: fetchClientExistingProjects,
        reset: reset,
        setPagingInfo: setPagingInfo,
    });

    useEffect(() => {
        setClientProjectsColumns(buildClientProjectsColumns({ visibleColumns, globalFilterRanges, filterOptions: filterMenu }));
        setSubclientProjectsColumns(buildSubclientProjectsColumns(buildClientProjectsColumns({ visibleColumns, globalFilterRanges, filterOptions: filterMenu })));
    }, [filterMenu, globalFilterRanges, visibleColumns]);

    const rowClick = (row) => {
        navigate(`/project/${row.id}/existing`);
        appInsights.trackEvent(
            { name: `Navigation - ${getTerm("existingProjects")} List` },
            {
                label: row.id,
                page: document.title.replace(" - Orgaimi", ""),
            }
        );
    };

    const data = clientExistingProjects.map(mapToTableData);
    return (
        <TableLoader
            allowFilter={true}
            columns={hasSubClients ? subclientProjectsColumns : clientProjectsColumns}
            containerClass="project-list"
            data={data}
            filters={filters}
            hiddenColumns={hiddenColumns}
            isLoading={isLoading}
            isSortAsc={isSortAsc}
            onRowClick={rowClick}
            pageCount={pageCount}
            pageIndex={pageIndex}
            pageSize={pageSize}
            setFilters={setFilters}
            setHiddenColumns={setHiddenColumns}
            setPageIndex={setPageIndex}
            setPerPage={setPerPage}
            setSort={setSort}
            sort={sort}
        />
    );
};

export default ExistingProjects;
