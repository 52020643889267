import PaginatedStore from "./base/PaginatedStore";
import endpoints from "../services/endpoint";

export const DEFAULT_SORT = { name: "asc" };
//TODO: Endpoint
const PROJECTS_MANAGED_STORAGE_KEY = `/api/projects/existing${endpoints("managed_list")}`;

const { store, fetchList, reset, setFilters, setHiddenColumns, setLoading, setPagingInfo, setPageIndex, setPerPage, setSort } = PaginatedStore({
    key: "projectsManaged",
    label: "Projects Managed",
    apiListUrl: PROJECTS_MANAGED_STORAGE_KEY,
    defaultSort: DEFAULT_SORT,
    persistPagingInfo: false,
    storageKey: PROJECTS_MANAGED_STORAGE_KEY,
});

export const ProjectsManagedStore = store;

export const fetchProjectsManaged = fetchList;

export { reset, setHiddenColumns, setLoading, setFilters, setPagingInfo, setPageIndex, setPerPage, setSort };
