import React, { useEffect, useState } from "react";
import _ from "lodash";
import classNames from "classnames";

import EditOptionDialog from "$components/form-inputs/select/components/edit-option";
import Select from "$components/form-inputs/select";
import ShareModal from "$components/modal/share-modal";

import { Input } from "$components/form-inputs/inputs";
import { useInput } from "$utils/hooks/use-input";
import { retrieveValue } from "$services/local";
import { AppContextStore, saveFilter, deleteSavedFilter, replaceCurrentFilters } from "$stores/app-context";
import { UserStore } from "$stores/user";
import { dialogConfirm, initiateModal } from "$stores/modal";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

var errorTimer = null;

const SavedFilters = ({ pauseClose }) => {
    const appInsights = useAppInsightsContext();
    const { currentFilters, selectedSavedFilter } = AppContextStore.useState((f) => {
        return { currentFilters: f.currentFilters || {}, selectedSavedFilter: f.selectedSavedFilter };
    });
    const user = UserStore.useState((s) => s);
    const savedFilters = AppContextStore.useState((f) => f.savedFilters || {});
    const selectFilter = useInput("");
    const inputFilterName = useInput("");
    const [error, setError] = useState("");

    useEffect(() => {
        const fetchFilters = async () => {
            const response = await retrieveValue("saved-filters");
            if (response) {
                setSavedFilters(response);
            }
        };

        fetchFilters();
    }, []);

    useEffect(() => {
        clearTimeout(errorTimer);
        if (error) {
            errorTimer = setTimeout(() => {
                setError("");
            }, 5000);
        }
    }, [error]);

    useEffect(() => {
        if (selectFilter.value?.value) {
            const currentFilters = savedFilters[selectFilter.value.value] ? JSON.parse(savedFilters[selectFilter.value.value].filters) || {} : {};
            replaceCurrentFilters(currentFilters, selectFilter.value?.text);
        }
    }, [selectFilter.value]);

    useEffect(() => {
        if (!selectedSavedFilter && selectFilter) {
            selectFilter.setValue("");
        } else if (selectedSavedFilter != selectFilter) {
            selectFilter.setValue(selectedSavedFilter);
        }
    }, [selectedSavedFilter]);

    const handleSelectChange = (name, option) => {
        selectFilter.setValue(option);
        if (!option || !option?.value) {
            // Clear Fitlers
            replaceCurrentFilters({}, "");
        }
    };

    const handleSave = async () => {
        if (inputFilterName.value && inputFilterName.value.startsWith("==")) {
            // Import Filter
            const filterString = atob(inputFilterName.value.replace("==", ""));
            replaceCurrentFilters(JSON.parse(filterString), "");
            inputFilterName.reset();
            return false;
        }

        if (_.isEmpty(currentFilters)) {
            setError("No filters are currently set");
            return false;
        }

        if (inputFilterName.value) {
            // If input value, save new
            const newOption = { text: inputFilterName.value };
            saveFilter(
                {
                    ...newOption,
                },
                currentFilters
            );

            inputFilterName.reset();
            selectFilter.setValue(newOption);
        } else if (selectFilter.value?.value) {
            // Else if select value override
            pauseClose(true);
            const confirmSave = await dialogConfirm({
                message: selectFilter.value?.text,
                type: "confirm",
            });
            if (!confirmSave) {
                return false;
            }

            saveFilter(selectFilter.value, currentFilters);
        } else if (!inputFilterName.value) {
            setError("Filter name required to save!");
        }
    };

    const updateFilter = (filter, data) => {
        const updatedFilter = { ...filter, text: data.text };
        // UPDATE
        if (data.action === "update") {
            // Update Selected if active
            if (selectFilter.value.value === updatedFilter.value) {
                selectFilter.setValue(updatedFilter);
            }
            saveFilter(updatedFilter, JSON.parse(updatedFilter.filters));
        }

        // DELETE
        if (data.action === "delete") {
            selectFilter.reset();
            deleteSavedFilter(filter.value);
            replaceCurrentFilters({}, filter.value);
        }
    };

    const triggerFilterShare = () => {
        initiateModal(
            ShareModal,
            {
                type: "filter",
                pageName: "Filters",
                notificationUrl: `/shared-filters?sharedFilters=${encodeURIComponent(`==${btoa(JSON.stringify(currentFilters))}`)}&fromUser=${encodeURIComponent(user.displayName)}`,
                PreviewComp: () => <p>Please select the people you would like to share this filter with:</p>,
                analyticsLabel: "Filters",
            },
            { analyticsLabel: "Filters" }
        );
    };

    return (
        <div className="saved-filters">
            <hr className="-dark" />
            {!_.isEmpty(savedFilters) && (
                <Select
                    label="Saved Filters"
                    options={Object.keys(savedFilters).map((f) => savedFilters[f])}
                    defaultValue={selectFilter.value}
                    updateOnChange={handleSelectChange}
                    updateOption={updateFilter}
                    EditComponent={EditOptionDialog}
                />
            )}
            <div id="import-save" className="button-group -full">
                <Input id="import-save" placeholder="New Filter Name" {...inputFilterName.bind} />
                <button className={classNames("button -primary -small -right", { "-secondary": inputFilterName.value.startsWith("==") })} onClick={handleSave}>
                    {inputFilterName.value.startsWith("==") ? "Import" : "Save"}
                </button>
            </div>
            {Object.keys(currentFilters).length > 0 && (
                <div className="action-items button-group -left">
                    {/*Object.keys(currentFilters).length && (
                        <button
                            className="button -text txt -gray -right"
                            onClick={() => {
                                copyToClipboard();
                            }}
                        >
                            Copy Filters
                        </button>
                        )*/}
                    {Object.keys(currentFilters).length && (
                        <button
                            className="button -text txt -gray -right"
                            onClick={() => {
                                triggerFilterShare();
                            }}
                        >
                            Share Filters
                        </button>
                    )}
                    <button
                        className="button -text txt -gray"
                        onClick={() => {
                            replaceCurrentFilters({}, "");

                            appInsights.trackEvent(
                                { name: "Filters - Clear" },
                                {
                                    label: "Clear",
                                    page: document.title.replace(" - Orgaimi", ""),
                                }
                            );
                        }}
                    >
                        Clear Filters
                    </button>
                </div>
            )}
            {error && <p className="save-error txt -small -error">{error}</p>}
        </div>
    );
};

export default SavedFilters;
