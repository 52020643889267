import React, { useState } from "react";

import InitialsCell from "$components/table/cells/initials";
import Table from "$components/table";

import { formatNumberWithCommas } from "$utils/number-helper";
import { getTerm } from "$stores/dictionary";
import { sortData } from "$components/table/lib/helpers";

const buildDefaultColumns = () => {
    return [
        {
            Header: () => getTerm("teamMember"),
            accessorKey: "name", // accessor is the "key" in the data
            id: "name", // id is used as a sort key (i.e., actual api name)
            Cell: ({ data, value }) => <InitialsCell url={data?.id ? `/team/${data.id}` : null} isActive={data.isActive} value={value} />,
        },
        {
            Header: () => "Role",
            accessorKey: "role",
            id: () => "role",
            Cell: ({ data, value }) => {
                return <span className="txt -xsmall -gray">{value}</span>;
            },
        },
        {
            Header: () => getTerm("hoursBilled"),
            accessorKey: "hoursBilled",
            id: "hoursBilled",
            sortDescFirst: true,
            Cell: ({ data, value }) => {
                return <h4 className="txt -light -white">{formatNumberWithCommas(value)}</h4>;
            },
        },
        {
            Header: () => `${getTerm("hoursBilled")} ${getTerm("TD")}`,
            accessorKey: "totalHoursBilled",
            id: "totalHoursBilled",
            enableRowSpan: true,
            sortDescFirst: true,
            Cell: ({ data, value }) => {
                return <h2 className="txt -white -no-margin">{formatNumberWithCommas(value)}</h2>;
            },
        },
        {
            Header: () => getTerm("revenueTD"),
            accessorKey: "revenue",
            id: "revenue",
            enableRowSpan: true,
            sortDescFirst: true,
            Cell: ({ data, value }) => {
                return (
                    <h2 className="txt -healthy -no-margin">
                        <sup>$</sup>
                        {formatNumberWithCommas(Math.round(value)) || 0}
                    </h2>
                );
            },
        },
    ];
};

const BillingByTeam = ({ data, revenue, hours }) => {
    const [defaultColumns] = useState(buildDefaultColumns());
    const [sort, setSort] = useState({ name: "asc" });
    const [billingData, setBillingData] = useState(
        sortData(
            data.map((i) => {
                return { ...i, revenue, totalHoursBilled: hours };
            }),
            sort
        )
    );

    const manualSort = (s) => {
        setSort(s);
        const sortedBillingData = sortData(billingData, s);
        setBillingData(sortedBillingData);
    };

    return (
        <div className="billing-by-team">
            <div className="flex-header">
                <h3>Billing By {getTerm("teamMember")}</h3>
                {/*<h4 className="txt -right -no-margin">
                    <span className="memo">* Billings under 10 hours are omitted</span>
                </h4>*/}
            </div>
            <Table data={billingData} revenue hours columns={defaultColumns} hiddenColumns={[]} sort={sort} setSort={manualSort} showPagination={false} />
        </div>
    );
};

export default BillingByTeam;
