import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import TableLoader from "$components/table/table-loader.js";

import { ExistingProjectsStore, fetchExistingProjects, reset, setFilters, setHiddenColumns, setPageIndex, setPagingInfo, setPerPage, setSort } from "../../../stores/existingProjects.js";
import { buildDefaultColumns } from "./libs/columns.js";
import { setHelpContent } from "$stores/help.js";
import { HELP_EXISTING_PROJECT_LIST } from "src/pages/help/lib/help-contents.js";
import { AppContextStore } from "$stores/app-context.js";
import { mapByKey } from "$utils/mapping.js";
import { usePaginatedStoreWrapper } from "$utils/hooks/usePaginatedStore.js";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { getTerm } from "$stores/dictionary.js";

export const mapToTableData = (c) => {
    return {
        key: c.id,
        ...c,
    };
};

const ExistingProjectList = () => {
    const navigate = useNavigate();
    const appInsights = useAppInsightsContext();

    const { filterMenu, visibleColumns } = AppContextStore.useState((f) => ({ filterMenu: mapByKey(f.filterMenu, "apiKey") || {}, visibleColumns: f.siteConfig?.existingProjectListColumns || null }));

    const { existingProjects, filters, globalFilterRanges, hiddenColumns, isLoading, isSortAsc, pageCount, pageIndex, pageSize, sort } = usePaginatedStoreWrapper(ExistingProjectsStore, {
        fetchList: fetchExistingProjects,
        reset: reset,
        setPagingInfo: setPagingInfo,
    });

    useEffect(() => {
        setHelpContent(HELP_EXISTING_PROJECT_LIST);
    }, []);

    const rowClick = (row) => {
        navigate(`/project/${row.key}/existing`);

        appInsights.trackEvent(
            { name: `Navigation - ${getTerm("existingProject")} List` },
            {
                label: row.id,
                page: document.title.replace(" - Orgaimi", ""),
            }
        );
    };

    const data = existingProjects.map(mapToTableData);
    return (
        <TableLoader
            allowFilter={true}
            columns={buildDefaultColumns({ visibleColumns, filterOptions: filterMenu, globalFilterRanges })}
            containerClass="project-list"
            data={data}
            filters={filters}
            isLoading={isLoading}
            isSortAsc={isSortAsc}
            hiddenColumns={hiddenColumns}
            onRowClick={rowClick}
            pageCount={pageCount}
            pageIndex={pageIndex}
            pageSize={pageSize}
            setFilters={setFilters}
            setHiddenColumns={setHiddenColumns}
            setPageIndex={setPageIndex}
            setPerPage={setPerPage}
            setSort={setSort}
            sort={sort}
        />
    );
};

export default ExistingProjectList;
