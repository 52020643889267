import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useLocation } from "react-router-dom";
import { format } from "date-fns";
import classnames from "classnames";

import ExpertPanel from "./panels/expert";
import MyClientsPanel from "./panels/my-clients";
import MyProjectsPanel from "./panels/my-projects";
import AllFirmPanel from "./panels/all-firm";
import SearchPanel from "./panels/search";
import PanelNav from "./components/panel-nav";

import { fetchPotentialRevenue } from "../../stores/opportunities";
import { AppContextStore } from "../../stores/app-context";
import { UserStore } from "../../stores/user";
import { PanelContext } from "../../contexts/panels";

import { SHORT_DATE_FORMAT, STANDARD_DATE_FORMAT } from "$utils/date-helpers";
import { handleAbort } from "$services/api";
import { getTerm } from "$stores/dictionary";

import "./growth-potential-panel.scss";

// TODO: Main date placeholder

const GrowthPotentialPanel = () => {
    const { panels, closePanel, clearTimer } = useContext(PanelContext);
    const profile = UserStore.useState((u) => u);
    const { dataDate, lastDataUpdate, enableExperiencedTeamMembers } = AppContextStore.useState((f) => ({
        dataDate: f.versions.latestPeriodDate,
        lastDataUpdate: f.versions.lastModelRun,
        enableExperiencedTeamMembers: f.settings?.sections?.experiencedTeamMembers || false,
    }));

    const [currentHash, setCurrentHash] = useState("#myclients");
    let location = useLocation();

    useEffect(() => {
        const hash = location.hash.split("?");
        if (hash[0]) {
            setCurrentHash(hash[0]);
        }
    }, [location]);

    useEffect(() => {
        let controllerPotentialRevenue;
        const $panelRoot = document.getElementById("panel-root");
        if ($panelRoot) {
            if (panels["growthPotential"]) {
                controllerPotentialRevenue = fetchPotentialRevenue({ crm: profile.id, enableExperiencedTeamMembers });
                $panelRoot.classList.add("-active");
            } else {
                $panelRoot.classList.remove("-active");
            }
        }

        return () => {
            handleAbort([controllerPotentialRevenue]);
        };
    }, [panels["growthPotential"], profile]);

    const setFirstHash = (firstHash) => {
        if (firstHash != currentHash) {
            setCurrentHash(firstHash);
        }
    };

    return ReactDOM.createPortal(
        <>
            <div
                id="growth-potential-panel"
                className={classnames("panel-contents", { "-active": panels["growthPotential"] })}
                onMouseLeave={() => {
                    closePanel("filters");
                }}
                onMouseEnter={() => {
                    clearTimer();
                }}
            >
                <div className="growth-potential-panel">
                    <header>
                        <div className="header-title">
                            <h2 className="txt -uppercase">
                                <span data-tooltip={getTerm("tooltipGrowthPotential")}>
                                    {getTerm("firmGrowthPotential")} {/* TODO: EM-it */}
                                </span>
                            </h2>

                            <h4 className="txt">
                                {dataDate && <div className="txt -gray -small -uppercase -compressed">For the month ending</div>}
                                <b className="txt -healthy">{dataDate ? format(dataDate, SHORT_DATE_FORMAT) : format(new Date(), SHORT_DATE_FORMAT)} </b>
                                {lastDataUpdate && (
                                    <>
                                        <br />
                                        <span> Updated: {format(lastDataUpdate, STANDARD_DATE_FORMAT)}</span>
                                    </>
                                )}
                            </h4>
                        </div>
                        <PanelNav currentHash={currentHash} setFirstHash={setFirstHash} />
                    </header>
                    {currentHash === "#myclients" && <MyClientsPanel />}
                    {currentHash === "#myprojects" && <MyProjectsPanel />}
                    {currentHash === "#allfirm" && <AllFirmPanel />}
                    {currentHash === "#expert" && <ExpertPanel />}
                    {currentHash === "#search" && <SearchPanel />}
                </div>
            </div>
        </>,
        document.getElementById("panel-root")
    );
};

export default GrowthPotentialPanel;
