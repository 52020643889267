import React, { useRef } from "react";

import BarComparison from "$components/charts/bar-comparison";
import useOnScreen from "$utils/hooks/use-onscreen";
import MoneyLineChart from "$components/charts/money-line";

import { checkIsHealthyByChange } from "$utils/health-helper";
import { COLOR_FONT, COLOR_POTENTIAL } from "$utils/colors";
import { stringTemplateReplace } from "$utils/template-replace";
import { getTerm } from "$stores/dictionary";

const FinancialPanel = ({ data, scores }) => {
    const ref = useRef();
    const isVisible = useOnScreen(ref);
    const hasData = !!data && data.clientCount > 0;
    const isHealthy = checkIsHealthyByChange(scores?.averageHealthScoreChange);

    return (
        <div ref={ref} className="financial-panel">
            <h3>Financial Performance</h3>
            {hasData && (
                <p className="office-info">
                    {data.officeName} | {data.officeManagingPartner}
                </p>
            )}
            {!hasData && <p>No data available</p>}
            {hasData && isVisible && (
                <div>
                    <div>
                        <ul className="legend">
                            <li className="selected">Selected Office</li>
                            <li className="average">Average of Offices</li>
                        </ul>
                    </div>
                    {data.charts.map((d) => {
                        const theTitle = !d.titleData
                            ? d.title
                            : d.titleData.reduce((memo, replacementKey, i) => {
                                  const newStr = memo.replace(`{${i}}`, data[replacementKey]);
                                  return newStr;
                              }, d.title);
                        return (
                            <div className="chart-item" key={d.id}>
                                <h4>{theTitle}</h4>
                                <BarComparison data={[{ value: d.office?.value }, { value: d.average?.value }]} colors={[COLOR_POTENTIAL, COLOR_FONT]} preSup={"$"} />
                            </div>
                        );
                    })}

                    {data.revenueHistory && data.revenueHistory.length > 0 && (
                        <div className="revenue-history">
                            <h4>{stringTemplateReplace(getTerm("tXRevenue"), { month: data.revenueHistory.length })} History</h4>

                            <div className="chart">
                                <MoneyLineChart revenueHistory={data.revenueHistory} isHealthy={isHealthy} />
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default FinancialPanel;
