import React from "react";
import { Link, useNavigate } from "react-router-dom";

import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

import "./breadcrumb.scss";

const Breadcrumb = ({ links = [] }) => {
    const appInsights = useAppInsightsContext();
    const navigate = useNavigate();
    const items = links.map((l, i) => {
        return (
            <li key={`breadcrumb_${i}`}>
                <Link to={l.url}>{l.text}</Link>
            </li>
        );
    });
    return (
        <div className="breadcrumb">
            <button
                className="back button -text"
                onClick={() => {
                    appInsights.trackEvent(
                        { name: "Navigation - Back" },
                        {
                            label: "Back",
                            page: document.title.replace(" - Orgaimi", ""),
                        }
                    );
                    navigate(-1);
                }}
            >
                <div className="circle">↩</div>Back
            </button>
            <ul>{items}</ul>
        </div>
    );
};

export default Breadcrumb;
