import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

import TableLoader from "$components/table/table-loader.js";

import { mapToTableData } from "src/pages/projects/existing/list.js";
import { ClientDetailsStore } from "$stores/client-details.js";
import { AppContextStore } from "$stores/app-context.js";
import { mapByKey } from "$utils/mapping.js";
import { usePaginatedStoreWrapper } from "$utils/hooks/usePaginatedStore.js";
import { ClientPotentialProjectsStore, fetchClientPotentialProjects, setFilters, setHiddenColumns, setPageIndex, setPagingInfo, setPerPage, setSelectedData, setSort } from "$stores/clientPotentialProjects.js";
import { buildDefaultColumns } from "src/pages/projects/potential/libs/columns";
import { getTerm } from "$stores/dictionary";

const ClientPotentialProjects = () => {
    const navigate = useNavigate();
    const appInsights = useAppInsightsContext();
    const { filterMenu, visibleColumns } = AppContextStore.useState((f) => {
        return { filterMenu: mapByKey(f.filterMenu, "apiKey") || {}, visibleColumns: f.siteConfig?.potentialProjectListColumns || null };
    });

    const [clientProjectsColumns, setClientProjectsColumns] = useState([]);

    const { hasSubClients, clientDetails } = ClientDetailsStore.useState((s) => {
        return { hasSubClients: s.hasSubClients, clientDetails: s.clientDetails };
    });
    const { clientPotentialProjects, filters, globalFilterRanges, hiddenColumns, isLoading, isSortAsc, pageCount, pageIndex, pageSize, selectedData, sort } = usePaginatedStoreWrapper(ClientPotentialProjectsStore, {
        fetchList: fetchClientPotentialProjects,
        setPagingInfo: setPagingInfo,
        overrideGlobalFilters: { client: [{ value: clientDetails.id, text: clientDetails.name }] },
        suppressGlobalFilters: true,
    });

    useEffect(() => {
        // Filter out clientGroupName from the default potential project columns
        const defaultColumns = buildDefaultColumns({ settings: { visibleColumns }, globalFilterRanges, filterOptions: filterMenu, updateSelectedList: setSelectedData }).filter((c) => c.accessorKey !== "clientGroupName");
        setClientProjectsColumns(hasSubClients ? defaultColumns : defaultColumns.filter((c) => c.accessorKey !== "clientName"));
    }, [hasSubClients, filterMenu, globalFilterRanges, visibleColumns]);

    const rowClick = (row) => {
        navigate(`/project/${row.id}/potential`);
        appInsights.trackEvent(
            { name: `Navigation - ${getTerm("potentialProject")} List` },
            {
                label: row.id,
                page: document.title.replace(" - Orgaimi", ""),
            }
        );
    };
    const data = clientPotentialProjects.map(mapToTableData);
    return (
        <TableLoader
            allowFilter={true}
            columns={clientProjectsColumns}
            containerClass="project-list"
            data={data}
            filters={filters}
            hiddenColumns={hiddenColumns}
            isLoading={isLoading}
            isSortAsc={isSortAsc}
            onRowClick={rowClick}
            pageCount={pageCount}
            pageIndex={pageIndex}
            pageSize={pageSize}
            selectedData={selectedData}
            setFilters={setFilters}
            setHiddenColumns={setHiddenColumns}
            setPageIndex={setPageIndex}
            setPerPage={setPerPage}
            setSort={setSort}
            sort={sort}
        />
    );
};

export default ClientPotentialProjects;
