import _ from "lodash";
import { Store } from "pullstate";
import api from "../services/api";
import logger from "../services/logger";
import { formatDate } from "../utils/date-helpers";

const initialState = {
    projectDetails: null,
    billingHistory: null,
    comparables: null,
    isLoading: true,
};

const fetchComparableData = async (comparableProjects) => {
    const groupedByRange = _.groupBy(comparableProjects, "projectValue");
    const comparableChart = Object.keys(groupedByRange).reduce((arr, key) => {
        arr.push({
            name: `$${key}`,
            count: groupedByRange[key].length,
        });
        return arr;
    }, []);

    try {
        ProjectDetailsStore.update((s) => {
            s.comparables = comparableChart;
        });
    } catch (err) {
        logger.error(`Unable to fetch project billing data: ${projectId}`, e);
    }
};

const apiProject = async (projectId, projectType, apiSettings) => {
    try {
        const result = await api.get(`/api/projects/${projectType}?project=${projectId}`, null, apiSettings);
        const currentProject = {
            name: result.name,
            projectId: result.id,
            type: result.projectState === 0 ? "potential" : "existing",
            projectType: result.projectType,
            clientId: result.clientGroupId,
            clientName: result.clientGroupName,
            crmId: result.crmId || "",
            crmIsActive: !!result.crmIsActive,
            crmName: result.crmName || "",
            crmEmail: result?.crmEmail || "",
            crmOffice: result.crmOffice,
            externalResourceLinks: result.externalResourceLinks ? JSON.parse(result.externalResourceLinks) : [],
            subClientName: result.clientName,
            subClientId: result.clientId,
            clientPIC: result.crmName,
            projectPicId: result.projectPicId,
            projectPIC: result.projectPic,
            projectPicIsActive: result.projectPicIsActive,
            projectTypeGroup: result.projectTypeGroup,
            averageRevenue: result.averageRevenue,
            revenueToDate: result.revenueToDate,
            probability: result.probability,
            team: result.team,
            startDate: formatDate(result.startDate),
            endDate: formatDate(result.endDate),
            status: result.status,
            totalHoursBilled: result.teamBillableHoursTotal,
            billingHistory: result.billingHistory,
            comparableProjects: result.comparableProjects,
            industry: result.industry,
            isCrossSell: result.isCrossSell,
            serviceLine: result.serviceLine,
            projectKey: result.projectKey,
            userFlagged: result.userFlagged,
            comments: [],
            requiresIndependence: result.requiresIndependence,
            hasSalesforceOpportunityCreated: result.hasSalesforceOpportunityCreated,
        };
        // async fetch chart data
        if (result.projectState === 0) {
            fetchComparableData(result.comparableProjects);
        }
        return ProjectDetailsStore.update((s) => {
            s.projectDetails = currentProject;
            s.billingHistory = (result.billingHistory || []).map((b) => {
                return {
                    date: formatDate(b.date),
                    billed: b.value,
                };
            });
            s.isLoading = false;
        });
    } catch (e) {
        logger.error(`Unable to fetch project: ${projectId}`, e);
        return null;
    }
};

export const fetchProject = (projectId, projectType) => {
    const abortController = new AbortController();
    apiProject(projectId, projectType, { signal: abortController.signal });
    return abortController;
};

export const reset = () => {
    ProjectDetailsStore.update((s) => {
        return initialState;
    });
};

export const ProjectDetailsStore = new Store(initialState);
