import React, { useEffect, useContext } from "react";
import { renderToString } from "react-dom/server";
import { debounce } from "debounce";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";
import am4geodata_usaLow from "@amcharts/amcharts4-geodata/usaLow";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

import LoadingOverlay from "$components/loaders/loading-overlay";
import MapLegend from "$components/map/legend";
import MapTooltip from "$components/map/tooltip";
import NoContent from "$components/no-content";

import { REACT_APP_AMCHART_KEY } from "$services/env";
import { fetchOffices, OfficeStore, setOfficesLoading } from "$stores/offices";
import { AppContextStore } from "$stores/app-context";
import { fetchClients } from "$stores/clients";
import { PanelContext } from "$contexts/panels";
import { getLatLngCenter, getLatLngZoom } from "$utils/map-helper";
import { checkIsHealthy } from "$utils/health-helper";
import { useDocumentTitle } from "$utils/document-title";
import { handleAbort } from "$services/api";
import { COLOR_BASE, COLOR_HEADER_BG } from "$utils/colors";
import { setHelpContent } from "$stores/help";
import { HELP_OFFICES } from "../help/lib/help-contents";

import "./offices.scss";

am4core.useTheme(am4themes_animated);
if (REACT_APP_AMCHART_KEY) {
    am4core.addLicense(REACT_APP_AMCHART_KEY);
}

// TODO: Something odd going on here
const debouncedFetchOffices = debounce((updatedFilters) => {
    const controllerClients = fetchClients(updatedFilters);
    const controllerFilters = fetchOffices(updatedFilters);

    return [controllerClients, controllerFilters];
}, 800);

const OfficesIndex = () => {
    useDocumentTitle("Offices");
    const appInsights = useAppInsightsContext();
    const { isLoading, offices } = OfficeStore.useState((o) => {
        return { offices: o.offices, isLoading: o.isLoading };
    });
    const currentFilters = AppContextStore.useState((f) => f.currentFilters || {});
    const hasLoadedFilters = AppContextStore.useState((f) => f.hasLoadedFilters);
    const { togglePanel } = useContext(PanelContext);

    useEffect(() => {
        window.addEventListener("hashchange", openPanel);

        setHelpContent(HELP_OFFICES);

        return () => {
            window.removeEventListener("hashchange", openPanel);
            togglePanel("officeDetails", false);
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        let controllers = [];
        if (hasLoadedFilters) {
            window.location.hash = ""; // This resets the ability to open the offices panel
            setOfficesLoading(true);
            controllers = debouncedFetchOffices(currentFilters);
        }

        return () => {
            handleAbort(controllers);
        };
    }, [currentFilters, hasLoadedFilters]);

    const openPanel = () => {
        togglePanel("officeDetails", false);
        setTimeout(() => {
            togglePanel("officeDetails", !!window.location.hash);
        }, 500);

        if (window.location.hash) {
            appInsights.trackEvent(
                { name: "Offices" },
                {
                    label: "Office Detail Panel",
                    data: window.location.hash,
                    page: document.title.replace(" - Orgaimi", ""),
                }
            );
        }
    };

    useEffect(() => {
        // if (!offices || offices.length === 0) {
        //     return;
        // }
        const mapData = offices.map((d) => {
            return {
                ...d,
                color: checkIsHealthy(d.currentAverage) ? "#18b2dc" : "#fd9332",
                tooltip: renderToString(
                    <MapTooltip
                        id={d.id}
                        currentAverageScore={d.currentAverage}
                        scoreChange={d.averageChange}
                        officeLabel={d.officeName}
                        officeManagingPartner={d.officeManagingPartner}
                        officeManagingPartnerId={d.officeManagingPartnerId}
                        officeManagingPartnerIsActive={d.officeManagingPartnerIsActive}
                    />
                ),
            };
        });
        // Create map instance
        let chart = am4core.create("map", am4maps.MapChart);

        // Set map definition
        chart.geodata = am4geodata_worldLow;

        // Set projection
        chart.projection = new am4maps.projections.Miller();

        // Create map polygon series
        let polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());

        // Make map load polygon (like country names) data from GeoJSON
        polygonSeries.useGeodata = true;
        polygonSeries.nonScalingStroke = true;
        polygonSeries.strokeWidth = 0.5;
        polygonSeries.calculateVisualCenter = true;
        polygonSeries.exclude = ["AQ"];

        // BG Colors
        chart.backgroundSeries.mapPolygons.template.polygon.fill = am4core.color(COLOR_BASE);
        chart.backgroundSeries.mapPolygons.template.polygon.fillOpacity = 1;

        // Configure series
        let polygonTemplate = polygonSeries.mapPolygons.template;
        polygonTemplate.polygon.fillOpacity = 1;
        polygonTemplate.fill = am4core.color(COLOR_HEADER_BG);
        polygonTemplate.stroke = am4core.color(COLOR_BASE);

        // Series for United States map
        let usaSeries = chart.series.push(new am4maps.MapPolygonSeries());
        usaSeries.geodata = am4geodata_usaLow;

        let usPolygonTemplate = usaSeries.mapPolygons.template;
        usPolygonTemplate.polygon.fillOpacity = 1;
        usPolygonTemplate.fill = am4core.color(COLOR_HEADER_BG);
        usPolygonTemplate.stroke = am4core.color(COLOR_BASE);
        usPolygonTemplate.nonScalingStroke = true;

        // Add image series
        let imageSeries = chart.series.push(new am4maps.MapImageSeries());
        imageSeries.data = mapData;
        imageSeries.dataFields.value = "currentAverage";
        imageSeries.mapImages.template.propertyFields.longitude = "longitude";
        imageSeries.mapImages.template.propertyFields.latitude = "latitude";
        imageSeries.mapImages.template.propertyFields.url = "url";

        let imageTemplate = imageSeries.mapImages.template;
        imageTemplate.nonScaling = true;

        imageSeries.tooltip.label.interactionsEnabled = true;
        imageSeries.tooltip.keepTargetHover = true;
        let circle = imageSeries.mapImages.template.createChild(am4core.Circle);
        //circle.radius = 3;
        circle.fillOpacity = 0.7;
        circle.propertyFields.fill = "color";
        imageTemplate.interactionsEnabled = true;
        imageSeries.tooltip.interactionsEnabled = true;
        imageSeries.tooltip.label.interactionsEnabled = true;
        chart.interactionsEnabled = true;

        circle.tooltipHTML = "{tooltip}";

        imageSeries.tooltip.getFillFromObject = false;
        imageSeries.tooltip.background.fill = am4core.color("#999");
        imageSeries.tooltip.background.opacity = 0.5;
        imageSeries.tooltip.label.padding(0, 0, 0, 0);
        imageSeries.tooltip.background.stroke = false; //am4core.color(!true ? "#18b2dc" : "#fd9332");
        //imageSeries.tooltip.background.strokeWidth = 2;
        // Removes panel on map click
        chart.events.on("hit", function (ev) {
            window.location.hash = "";
            togglePanel("officeDetails", false);
        });
        imageSeries.heatRules.push({
            target: circle,
            property: "radius",
            min: 10,
            max: 20,
            dataField: "value",
            fill: "#000000",
        });

        imageTemplate.adapter.add("latitude", function (latitude, target) {
            let polygon = polygonSeries.getPolygonById(target.dataItem.dataContext.id);
            if (polygon) {
                return polygon.visualLatitude;
            }
            return latitude;
        });

        imageTemplate.adapter.add("longitude", function (longitude, target) {
            let polygon = polygonSeries.getPolygonById(target.dataItem.dataContext.id);
            if (polygon) {
                return polygon.visualLongitude;
            }
            return longitude;
        });

        imageSeries.data = mapData;

        const latLongs = mapData.map((d) => [d.latitude, d.longitude]);
        const zoomLevel = getLatLngZoom(latLongs);
        const center = getLatLngCenter(latLongs, zoomLevel);
        chart.homeZoomLevel = zoomLevel;
        chart.homeGeoPoint = {
            latitude: center[0],
            longitude: center[1],
        };

        return () => {
            chart.dispose();
        };
        // eslint-disable-next-line
    }, [offices]);

    return (
        <div id="offices">
            {isLoading && <LoadingOverlay />}
            {!isLoading && offices && offices.length === 0 && (
                <div className="no-map">
                    <NoContent text="No Data to Display With Current Filter" />
                </div>
            )}
            <div id="map"> </div>
            <MapLegend />
        </div>
    );
};

export default OfficesIndex;
