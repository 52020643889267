import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { debounce } from "lodash";

import GlobalDashboard from "$components/dashboard";
import FilterStats from "$components/filter-stats";

import { AppContextStore, fetchOverview } from "$stores/app-context";
import { getTerm } from "$stores/dictionary";

var pastFiltersString = "";

const debouncedFetchOverview = debounce(async (currentFilters) => {
    fetchOverview(currentFilters);
}, 800);

const FilterStatsRoute = ({ component: Component, ...rest }) => {
    const { hasLoadedFilters, currentFilters } = AppContextStore.useState((f) => {
        return { hasLoadedFilters: f.hasLoadedFilters, currentFilters: f.currentFilters || {}, overviewLoading: f.overviewLoading };
    });
    const location = useLocation();
    const isPotential = location.pathname === "/projects/potential";
    const title = isPotential ? getTerm("potentialProjects") : getTerm("existingProjects"); // TODO: <em>-ify
    const tooltip = isPotential ? getTerm("tooltipTopPotentialProjects") : null;

    useEffect(() => {
        if (JSON.stringify(currentFilters) !== pastFiltersString) {
            debouncedFetchOverview(currentFilters);
            pastFiltersString = JSON.stringify(currentFilters);
        }
    }, [currentFilters]);

    return (
        <div className="page-container">
            <GlobalDashboard>
                <FilterStats />
            </GlobalDashboard>

            <Outlet />
        </div>
    );
};
export default FilterStatsRoute;
