import { Store } from "pullstate";
import api from "$services/api";

const initialState = {
    comments: [],
};

export const CommentsStore = new Store(initialState);

export const fetchComments = async (projectId, page, limit) => {
    let queryString = `?page=${page}&limit=${limit}`;
    queryString += projectId ? `&projectId=${projectId}` : "";
    const response = await api.get(`/api/admin/comments${queryString}`);
    CommentsStore.update((s) => {
        s.comments = [...response];
    });
};

export const deleteComment = (commentId) => {
    return api.delete(`/api/admin/comments/${commentId}`);
};
