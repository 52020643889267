import React, { useEffect } from "react";

import ClientList from "./list";

import { useDocumentTitle } from "$utils/document-title";
import { setHelpContent } from "$stores/help";
import { HELP_CLIENT_LIST } from "../help/lib/help-contents";

const ClientIndex = () => {
    useDocumentTitle("Client List");

    useEffect(() => {
        setHelpContent(HELP_CLIENT_LIST);
    }, []);

    return (
        <div id="client-list" className="page">
            <div className="main -spacer">
                <main>
                    <div className="main-wrapper">
                        <h1>
                            <em>Client</em> List
                        </h1>
                        <ClientList />
                    </div>
                </main>
            </div>
        </div>
    );
};

export default ClientIndex;
