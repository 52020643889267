import React from "react";
import ProjectFlags from "./projects";
import ProjectExpertFlags from "./project-flags";

const FlagsAdminIndex = () => {
    return (
        <>
            <ProjectFlags />
            <ProjectExpertFlags />
        </>
    );
};

export default FlagsAdminIndex;
