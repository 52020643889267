import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShareNodes } from "@fortawesome/free-solid-svg-icons";

import MobileClientNav from "./mobile-client-nav";

import ShareModal from "../../../components/modal/share-modal";

import { NavLink } from "react-router-dom";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { ClientDetailsStore } from "$stores/client-details";
import { getTerm } from "$stores/dictionary";
import { AppContextStore } from "$stores/app-context";
import { initiateModal } from "$stores/modal";

const buildNavLinks = () => {
    return [
        {
            id: "client-nav-dashboard",
            text: "Dashboard",
            url: "",
        },
        {
            id: "client-nav-subclients",
            text: getTerm("subClients"),
            url: "/sub-clients",
            requiresSubClients: true,
        },
        {
            id: "client-nav-growth-potential",
            text: getTerm("growthPotential"),
            url: "/growth-potential",
        },
        {
            id: "client-nav-recommendations",
            text: "Recommendations",
            url: "/recommendations",
        },
        /*{
            id: "client-nav-scores",
            text: "Scores",
            url: "/scores",
        },*/
        {
            id: "client-nav-existing-projects",
            text: getTerm("existingProjects"),
            url: "/existing-projects",
        },
    ];
};

const ClientNav = ({ clientId }) => {
    const appInsights = useAppInsightsContext();
    const hasSubClients = ClientDetailsStore.useState((s) => s.hasSubClients);
    const basepath = `/client/${clientId}`;
    const [navLinks] = useState(buildNavLinks());
    const { enableShare } = AppContextStore.useState((a) => {
        return { enableShare: a.settings?.externalConnections?.share };
    });

    const trackEvent = (label) => {
        appInsights.trackEvent(
            { name: `Navigation - ${getTerm("parentClient")} Menu` },
            {
                label: label,
                page: document.title.replace(" - Orgaimi", ""),
            }
        );
    };

    const triggerShareModal = () => {
        initiateModal(ShareModal, { type: "client", analyticsLabel: getTerm("parentClient") }, { analyticsLabel: getTerm("parentClient") });
    };

    return (
        <div className="client-nav">
            <MobileClientNav hasSubClients={hasSubClients} navLinks={navLinks} basepath={basepath} />
            <div className="button-list -underline">
                {navLinks.map((l, i) => {
                    if (l.requiresSubClients && !hasSubClients) {
                        return null;
                    }
                    return (
                        <NavLink
                            key={`client_nav_${i}`}
                            id={l.id}
                            end
                            className={({ isActive }) => (isActive ? "-active" : undefined)}
                            to={`${basepath}${l.url}`}
                            onClick={() => {
                                trackEvent(l.text);
                            }}
                        >
                            {l.text}
                        </NavLink>
                    );
                })}
            </div>
            {enableShare && (
                <button className="button -text" onClick={triggerShareModal}>
                    Share <FontAwesomeIcon icon={faShareNodes} />
                </button>
            )}
        </div>
    );
};

export default ClientNav;
