import React, { useState, useEffect } from "react";
import { fetchPotentialProjectFlags, FlagsStore, deletePotentialProjectFlag } from "./store";
import { format } from "date-fns";
import debounce from "debounce";
import usePagination from "../../hooks/usePagination";
import Pagination from "$components/table/components/pagination";
import ConfirmButton from "../../components/ConfirmButton";

const debouncedFetchProjectFlags = debounce(fetchPotentialProjectFlags, 500);

const ProjectFlags = () => {
    const potentialProjectFlags = FlagsStore.useState((s) => s.potentialProjectFlags);
    const { page, setPage, limit, setLimit } = usePagination();
    const [totalPages, setTotalPages] = useState(1);
    const [projectId, setProjectId] = useState();

    useEffect(() => {
        debouncedFetchProjectFlags(projectId, page, limit);
    }, [projectId, page, limit]);

    useEffect(() => {
        if (potentialProjectFlags && potentialProjectFlags.length > 0) {
            setTotalPages(Math.ceil(potentialProjectFlags[0].totalRecordCount / limit));
        }
    }, [potentialProjectFlags]);

    return (
        <>
            <div>
                <h1>Project Flags</h1>
                <div>
                    Filter by Project ID: <input type="text" value={projectId} onChange={(e) => setProjectId(e.currentTarget.value)} />
                </div>
                <table>
                    <tr>
                        <th>Id</th>
                        <th>Date</th>
                        <th>Project Id</th>

                        <th>Submitted By</th>
                        <th>Reason</th>
                        <th>User Feedback</th>
                        <th>Actions</th>
                    </tr>
                    {potentialProjectFlags?.map((flag) => (
                        <tr key={flag.id}>
                            <td>{flag.id}</td>
                            <td>{format(new Date(flag.flaggedDate), "MMMM d, yyyy h:mm a")}</td>
                            <td>
                                <a href={`/project/${flag.projectId}/potential`}>{flag.projectId}</a>
                            </td>

                            <td>{flag.submitterDisplayName}</td>
                            <td>{flag.reason}</td>
                            <td>{flag.userFeedback}</td>
                            <td>
                                <ConfirmButton
                                    onClick={() => {
                                        deletePotentialProjectFlag(flag.id).then(() => {
                                            debouncedFetchProjectFlags(projectId, page, limit);
                                        });
                                    }}
                                    text="Delete"
                                />
                            </td>
                        </tr>
                    ))}
                </table>
                <Pagination
                    pageIndex={page}
                    pageCount={totalPages}
                    pageSize={limit}
                    setPageIndex={setPage}
                    setPerPage={setLimit}
                    canPreviousPage={page > 1}
                    canNextPage={page < totalPages - 1}
                    pageOptions={Array.from({ length: totalPages }, (x, i) => i)}
                />
            </div>
        </>
    );
};

export default ProjectFlags;
