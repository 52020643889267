import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";

import AdminIndex from "./pages/admin";
import AdminLayout from "./pages/admin/layout";
import AuthRoute from "./routes/AuthRoute";
import ClientDetailLayout from "./pages/clients/detail";
import ClientExistingProjects from "./pages/clients/existing-projects";
import ClientIndex from "./pages/clients";
import ClientDashboard from "./pages/clients/dashboard";
import ClientGrowthPotential from "./pages/clients/growth-potential";
import ClientRecommendations from "./pages/clients/recommendations";
import ClientScores from "./pages/clients/scores";
import ClientSubClients from "./pages/clients/sub-clients";
import DashboardIndex from "./pages/dashboard";
import DictionaryAdmin from "./pages/admin/pages/dictionary";
import EmailIndex from "./emails";
import ExistingProjects from "./pages/projects/existing";
import FilterStatsRoute from "./routes/FilterStatsRoute";
import FilterListIndex from "./pages/admin/pages/filter-lists/filter-lists";
import GlobalError from "./components/global-error";
import Header from "./components/header";
import HelpIndex from "./pages/help";
import LoginIndex from "./components/login";
import Logout from "./components/login/logout";
import Modal from "$components/modal";
import NotifyAdmin from "./pages/admin/pages/notify";
import NotificationAdmin from "./pages/admin/pages/notification";
import ProjectSnapshotAdminIndex from "./pages/admin/pages/project-snapshots";
import CommentsAdminIndex from "./pages/admin/pages/comments";
import FlagsAdminIndex from "./pages/admin/pages/flags";
import ResetAdminIndex from "./pages/admin/pages/reset";
import Reset from "./components/login/reset";
import OfficesIndex from "./pages/offices";
import PotentialProjects from "./pages/projects/potential";
import ProjectDetail from "./pages/projects/detail";
import ProjectListLayout from "./pages/projects";
import SegmentsIndex from "./pages/segments";
import SharedFiltersIndex from "./pages/shared-filter";
import TeamIndex from "./pages/team";
import TeamDetailLayout from "./pages/team/detail";
import TeamDashboard from "./pages/team/dashboard";
import TeamPastWork from "./pages/team/past-work";
import TeamManaging from "./pages/team/managing";
import TeamExperience from "./pages/team/experience";

import { PanelState } from "./contexts/panels";
import { UserStore } from "./stores/user";

const RoutesMap = () => {
    const user = UserStore.useState((s) => s);

    return (
        <Router>
            <PanelState>
                {user.isAuthed && <Header />}
                {user.isAuthed && <GlobalError />}
                <Routes>
                    {!user.isAuthed && <Route exact path="/" element={<LoginIndex />} />}
                    <Route path="/email/:email" element={<EmailIndex />} />
                    <Route path="/logout" element={<Logout />} />
                    <Route path="/reset" element={<Reset />} />
                    <Route element={<AuthRoute />}>
                        <Route exact path="/" element={<DashboardIndex />} />
                        <Route element={<FilterStatsRoute />}>
                            <Route path="/segments" element={<SegmentsIndex />} />
                            <Route path="/offices" element={<OfficesIndex />} />
                            <Route path="/clients" element={<ClientIndex />} />
                            <Route element={<ProjectListLayout />}>
                                <Route exact path="/projects" element={<Navigate to="/projects/potential" />}></Route>
                                <Route path="/projects/potential" element={<PotentialProjects />} />
                                <Route path="/projects/existing" element={<ExistingProjects />} />
                            </Route>
                            <Route path="/team" element={<TeamIndex />}></Route>
                            <Route path="/shared-filters" element={<SharedFiltersIndex />} />
                        </Route>
                        <Route element={<ClientDetailLayout />}>
                            <Route path="/client/:clientId/growth-potential" element={<ClientGrowthPotential />} />
                            <Route path="/client/:clientId/sub-clients" element={<ClientSubClients />} />
                            <Route path="/client/:clientId/scores" element={<ClientScores />} />
                            <Route path="/client/:clientId/details" element={<ClientExistingProjects />} />
                            <Route path="/client/:clientId/existing-projects" element={<ClientExistingProjects />} />
                            <Route path="/client/:clientId/recommendations" element={<ClientRecommendations />} />
                            <Route exact path="/client/:clientId" element={<ClientDashboard />} />
                        </Route>

                        <Route element={<TeamDetailLayout />}>
                            <Route path="/team/:employeeId/past-work" element={<TeamPastWork />} />
                            <Route path="/team/:employeeId/managing" element={<TeamManaging />} />
                            <Route path="/team/:employeeId/experience" element={<TeamExperience />} />
                            <Route exact path="/team/:employeeId" element={<TeamDashboard />} />
                        </Route>
                        <Route path="/project/:projectId/:projectType" element={<ProjectDetail />} />
                        <Route path="/help" element={<HelpIndex />} />
                    </Route>
                    {user.isAdmin && user.isAuthed && (
                        <Route element={<AdminLayout />}>
                            <Route path="/admin" element={<AdminIndex />} />
                            <Route path="/admin/dictionary" element={<DictionaryAdmin />} />
                            <Route path="/admin/notify" element={<NotifyAdmin />} />
                            <Route path="/admin/notifications" element={<NotificationAdmin />} />
                            <Route path="/admin/filters" element={<FilterListIndex />} />
                            <Route path="/admin/projectsnapshots" element={<ProjectSnapshotAdminIndex />} />
                            <Route path="/admin/comments" element={<CommentsAdminIndex />} />
                            <Route path="/admin/flags" element={<FlagsAdminIndex />} />
                            <Route path="/admin/reset" element={<ResetAdminIndex />} />
                        </Route>
                    )}
                </Routes>
                {user.isAuthed && <Modal />}
            </PanelState>
        </Router>
    );
};

export default RoutesMap;
