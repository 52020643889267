import PaginatedStore from "./base/PaginatedStore";

export const DEFAULT_SORT = { name: "asc" };
const CLIENT_STORAGE_KEY = "/api/projects/existing";

const { store, fetchList, reset, setFilters, setHiddenColumns, setLoading, setPageIndex, setPagingInfo, setPerPage, setSort } = PaginatedStore({
    key: "existingProjects",
    label: "Existing Projects",
    apiListUrl: CLIENT_STORAGE_KEY,
    defaultSort: DEFAULT_SORT,
    persistPagingInfo: true,
    storageKey: CLIENT_STORAGE_KEY,
});

export const ExistingProjectsStore = store;

export const fetchExistingProjects = fetchList;

export { reset, setFilters, setHiddenColumns, setLoading, setPageIndex, setPagingInfo, setPerPage, setSort };
