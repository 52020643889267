import {  useState } from "react";

const usePagination = () => {
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);

    return {
        page,
        setPage,
        limit,
        setLimit
    };
};

export default usePagination;
