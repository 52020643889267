import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import RevenueChart from "./chart";
import ToggleSwitch from "$components/toggle-switch";

import { ClientDetailsStore } from "$stores/client-details";
import { getTerm } from "$stores/dictionary";
import { scrollToPos } from "$utils/scroll-to";

import "./twelve-month-revenue.scss";

const buildToggleOptions = () => {
    return [
        { label: getTerm("serviceLine"), value: 0 },
        { label: `Like ${getTerm("parentClients")}`, value: 1 },
    ];
};

const TwelveMonthRevenue = ({ clientId, handleClick }) => {
    const chartData = ClientDetailsStore.useState((s) => s.clientDetailsCharts);
    const [toggleOptions] = useState(buildToggleOptions());
    const { hash } = useLocation();
    const [tab, setTab] = useState(hash === "#likeClients" ? 1 : 0);
    const navigate = useNavigate();

    const tabChange = (value, index) => {
        setTab(index);
    };

    const highlightCurrentClient = (line) => {
        return line.key.toString() === clientId.toString() ? 4 : 1;
    };

    return (
        <div>
            <div className="flex-header">
                <h3>
                    <span data-tooltip={getTerm("tooltip12MonthRevenue")}>{getTerm("t12Revenue")}</span> By {getTerm("serviceLine")} {chartData?.likeClientData && <>&amp; Comparison To Like {getTerm("parentClients")}</>}
                </h3>
                {chartData?.likeClientData && <ToggleSwitch options={toggleOptions} updateField={tabChange} selectedIndex={tab} analyticsLabel={`${getTerm("t12Revenue")} Charts`} />}
            </div>
            <div className="twelve-month-reveue">
                {tab === 0 && chartData.serviceLineData && (
                    <RevenueChart
                        data={chartData.serviceLineData}
                        handleClick={(data) => {
                            handleClick("serviceLine", [{ text: data?.text, value: data?.id }]);
                        }}
                    />
                )}
                {tab === 1 && chartData.likeClientData && (
                    <RevenueChart
                        data={chartData.likeClientData}
                        highlightFn={highlightCurrentClient}
                        handleClick={(data) => {
                            scrollToPos(0);
                            navigate(`/client/${data.id}`);
                        }}
                    />
                )}
            </div>
        </div>
    );
};

export default TwelveMonthRevenue;
