import React, { useState, useEffect } from "react";
import { fetchProjectExpertFlags, FlagsStore, deleteProjectExpertFlag } from "./store";
import { format } from "date-fns";
import debounce from "debounce";
import usePagination from "../../hooks/usePagination";
import Pagination from "$components/table/components/pagination";
import ConfirmButton from "../../components/ConfirmButton";

const debouncedFetchProjectExpertFlags = debounce(fetchProjectExpertFlags, 500);

const ProjectExpertFlags = () => {
    const projectExpertFlags = FlagsStore.useState((s) => s.projectExpertFlags);
    const { page, setPage, limit, setLimit } = usePagination();

    const [expertFlagProjectId, setExpertFlagProjectId] = useState();
    const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {
        debouncedFetchProjectExpertFlags(expertFlagProjectId, page, limit);
    }, [expertFlagProjectId, page, limit]);

    useEffect(() => {
        if (projectExpertFlags && projectExpertFlags.length > 0) {
            setTotalPages(Math.ceil(projectExpertFlags[0].totalRecordCount / limit));
        }
    }, [projectExpertFlags]);

    return (
        <div>
            <h1>Project Expert Flags</h1>
            <div>
                Filter by Project ID: <input type="text" value={expertFlagProjectId} onChange={(e) => setExpertFlagProjectId(e.currentTarget.value)} />
            </div>
            <table>
                <tr>
                    <th>Date</th>
                    <th>Project Id</th>
                    <th>Flagged Employee</th>
                    <th>Submitted By</th>
                    <th>Reason</th>
                    <th>User Feedback</th>
                    <th>Actions</th>
                </tr>
                {projectExpertFlags?.map((flag) => (
                    <tr key={`${flag.projectId}-${flag.submitterUserId}-${flag.flaggedDate}`}>
                        <td>{format(new Date(flag.flaggedDate), "MMMM d, yyyy h:mm a")}</td>
                        <td>
                            <a href={`/project/${flag.projectId}/potential`}>{flag.projectId}</a>
                        </td>
                        <td>
                            <a href={`/team/${flag.flaggedEmployeeId}`}>{flag.flaggedEmployeeDisplayName}</a>
                        </td>
                        <td>{flag.submitterDisplayName}</td>
                        <td>{flag.reason}</td>
                        <td>{flag.userFeedback}</td>
                        <td>
                            <ConfirmButton
                                onClick={() =>
                                    deleteProjectExpertFlag(flag.projectKey, flag.flaggedEmployeeId, flag.submitterUserId).then(() => {
                                        debouncedFetchProjectExpertFlags(expertFlagProjectId, page, limit);
                                    })
                                }
                                text="Delete"
                            />
                        </td>
                    </tr>
                ))}
            </table>
            <Pagination
                pageIndex={page}
                pageCount={totalPages}
                pageSize={limit}
                setPageIndex={setPage}
                setPerPage={setLimit}
                canPreviousPage={page > 1}
                canNextPage={page < totalPages - 1}
                pageOptions={Array.from({ length: totalPages }, (x, i) => i)}
            />
        </div>
    );
};

export default ProjectExpertFlags;
