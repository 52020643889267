import PaginatedStore from "./base/PaginatedStore";
import endpoints from "../services/endpoint";

export const DEFAULT_SORT = { name: "asc" };
const CLIENT_STORAGE_KEY = `/api/client-groups${endpoints("clients_groups_list")}`;

const { store, fetchList, reset, setFilters, setHiddenColumns, setLoading, setPageIndex, setPagingInfo, setPerPage, setSort } = PaginatedStore({
    key: "clients",
    label: "Clients",
    apiListUrl: CLIENT_STORAGE_KEY,
    defaultSort: DEFAULT_SORT,
    persistPagingInfo: true,
    storageKey: CLIENT_STORAGE_KEY,
});

export const ClientStore = store;

export const fetchClients = fetchList;

export { reset, setFilters, setHiddenColumns, setLoading, setPageIndex, setPagingInfo, setPerPage, setSort };
